import myApi, { FirebaseApiResponse } from '../firebase/FirebaseApi';
import { base } from './BaseUrl';
import { OnboardingClientInfo, OnboardingTrainerInfo } from '../models/Data';

export const handleValidate = (
  data: OnboardingClientInfo,
  navigate: (path: string) => void,
  setError: (message: string) => void
) => {
  console.log(data);
  myApi
    .createSingleClient(base, data)
    .then((res: FirebaseApiResponse) => {
      console.log(res);
      if (typeof res === 'string' || (res as any).error) {
        setError(typeof res === 'string' ? res : (res as any).error);
      } else {
        // Navigate to the app's home page on success
        navigate('/home-page');
      }
    })
    .catch((error: any) => {
      console.error('An error occurred:', error);
      setError('An error occurred. Please try again.');
    });
};

export const handleValidateTrainer = (
  data: OnboardingTrainerInfo,
  navigate: (path: string) => void,
  setError: (message: string) => void
) => {
  console.log(data);
  myApi
    .createSingleTrainer(base, data)
    .then((res: FirebaseApiResponse) => {
      console.log(res);
      if (typeof res === 'string' || (res as any).error) {
        setError(typeof res === 'string' ? res : (res as any).error);
      } else {
        // Navigate to the trainer's home page on success
        navigate('/trainer-done');
      }
    })
    .catch((error: any) => {
      console.error('An error occurred:', error);
      setError('An error occurred. Please try again.');
    });
};

export const findClient = (uid: string, navigate: (path: string) => void) => {
  myApi.readSingleClient(base, uid!).then((res: FirebaseApiResponse) => {
    if (typeof res === 'string' || (res as any).error) {
      navigate('/client-validation');
    } else {
      // Navigate to the app's home page on success
      navigate('/home-page');
    }
  });
};

export const findTrainer = (uid: string, navigate: (path: string) => void) => {
  myApi.readSingleTrainer(base, uid!).then((res: FirebaseApiResponse) => {
    if (typeof res === 'string' || (res as any).error) {
      navigate('/trainer-information');
    } else {
      let data: any = res;
      if (data.validated) {
        navigate('/trainer-home');
      } else {
        navigate('/trainer-done');
        // handle ui error tell them to wait to get validated after 2-3 days.
      }
      // Navigate to the app's home page on success
    }
  });
};
