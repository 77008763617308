import {
  Typography,
  IconButton,
  TextField,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Button,
  Alert,
  Collapse,
} from '@mui/material';
import { VisibilityOff, Visibility, Close as CloseIcon, Google as GoogleIcon } from '@mui/icons-material';
import { firebaseAuth, googleProvider } from '../../../backend/firebase';
import { createUserWithEmailAndPassword, signInWithPopup, sendEmailVerification } from 'firebase/auth';
import { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUid } from '../../../backend/redux/store';
import { Appbar, GradientButton } from '../../components';
import { CustomCardContainer, CustomContainer, theme } from './styles';
import { ThemeProvider } from '@mui/material/styles';

export const ClientSignUpPage = () => {
  // State variables
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState<boolean>(false);
  const [generalError, setGeneralError] = useState<string>('');
  const [verificationSent, setVerificationSent] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Password textfield methods
  const handleClickShowPassword = (confirm: boolean) => {
    if (confirm) {
      setShowConfirmPassword((show) => !show);
    } else {
      setShowPassword((show) => !show);
    }
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  // Sign Up Functions
  const handleSignUp = async () => {
    setEmailError(false);
    setPasswordError(false);
    setConfirmPasswordError(false);
    setShowAlert(false);
    setGeneralError('');
    setVerificationSent(false);

    if (email === '') {
      setEmailError(true);
    }
    if (password === '') {
      setPasswordError(true);
    }
    if (confirmPassword === '') {
      setConfirmPasswordError(true);
    }

    if (email !== '' && password !== '' && confirmPassword !== '') {
      if (password !== confirmPassword) {
        setGeneralError('Passwords do not match. Please try again.');
        setShowAlert(true);
      } else {
        try {
          const userCredential = await createUserWithEmailAndPassword(firebaseAuth, email, password);

          await sendEmailVerification(userCredential.user);
          setVerificationSent(true);
          if (userCredential.user.emailVerified) {
            navigate('/client-validation');
            dispatch(setUid(userCredential.user.uid));
          }
        } catch (error: any) {
          if (error.code === 'auth/email-already-in-use') {
            setEmailError(true);
            setGeneralError('The email is already registered. Please sign in.');
            console.error(error);
          } else if (error.code === 'auth/invalid-email') {
            setEmailError(true);
            setGeneralError('Please enter a valid email.');
            console.error(error);
          } else if (error.code === 'auth/weak-password') {
            setPasswordError(true);
            setConfirmPasswordError(true);
            setGeneralError('Password should be at least 6 characters long.');
            console.error(error);
          }
          setShowAlert(true);
        }
      }
    } else {
      setGeneralError('Please fill in all fields to continue.');
      setShowAlert(true);
    }
  };

  const handleSignUpGoogle = async () => {
    try {
      await signInWithPopup(firebaseAuth, googleProvider).then((result) => {
        dispatch(setUid(result.user?.uid));
        navigate('/client-validation', { replace: true });
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        {/* AppBar */}
        <Appbar showMenu={true} />

        <CustomContainer>
          <CustomCardContainer>
            {/* Prompt Heading */}
            <Typography
              variant='h6'
              component='div'
              sx={{ marginTop: 2, marginBottom: 4, color: '#F27C22', fontWeight: 'bold' }}
            >
              Your fitness journey starts here!
            </Typography>

            {/* Email Input */}
            <TextField
              required
              error={emailError}
              id='outlined-basic'
              label='Email'
              variant='outlined'
              fullWidth
              sx={{
                marginBottom: 2,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '15px',
                  height: '55px',
                  '& .MuiOutlinedInput-notchedOutline': { border: '3px solid #000' },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: '3px solid #F27C22' },
                },
              }}
              InputLabelProps={{ style: { color: emailError ? theme.palette.error.main : '#000' } }}
              onChange={(e) => setEmail(e.target.value)}
            />

            {/* Password input */}
            <FormControl error={passwordError} required fullWidth sx={{ marginBottom: 2 }} variant='outlined'>
              <InputLabel htmlFor='outlined-adornment-password' sx={{ color: '#000' }}>
                Password
              </InputLabel>
              <OutlinedInput
                sx={{
                  borderRadius: '15px',
                  height: '55px',
                  '& .MuiOutlinedInput-notchedOutline': { border: '3px solid #000' },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: '3px solid #F27C22' },
                }}
                id='outlined-adornment-password'
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => handleClickShowPassword(false)}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label='Password'
              />
            </FormControl>

            {/* Confirm Password input */}
            <FormControl error={confirmPasswordError} required fullWidth sx={{ marginBottom: 4 }} variant='outlined'>
              <InputLabel htmlFor='outlined-adornment-confirm-password' sx={{ color: '#000' }}>
                Confirm Password
              </InputLabel>
              <OutlinedInput
                sx={{
                  borderRadius: '15px',
                  height: '55px',
                  '& .MuiOutlinedInput-notchedOutline': { border: '3px solid #000' },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: '3px solid #F27C22' },
                }}
                id='outlined-adornment-confirm-password'
                type={showConfirmPassword ? 'text' : 'password'}
                onChange={(e) => setConfirmPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle confirm password visibility'
                      onClick={() => handleClickShowPassword(true)}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label='Confirm Password'
              />
            </FormControl>

            {/* Error alert */}
            <Collapse in={showAlert || verificationSent}>
              <Alert
                severity={verificationSent ? 'success' : 'error'}
                action={
                  <IconButton aria-label='close' color='inherit' size='small' onClick={() => setShowAlert(false)}>
                    <CloseIcon fontSize='inherit' />
                  </IconButton>
                }
                sx={{ marginBottom: 2 }}
              >
                {verificationSent ? 'Verification email sent! Please check your inbox.' : generalError}
              </Alert>
            </Collapse>

            {/* Sign up button */}
            <GradientButton showArrowIcon text='Sign Up' handleClick={handleSignUp} />

            <hr style={{ color: 'black', width: '100%', marginTop: 25, marginBottom: 25 }} />

            {/* Sign in with Google button */}
            <Button
              variant='outlined'
              onClick={handleSignUpGoogle}
              startIcon={<GoogleIcon htmlColor='#F27C22' />}
              sx={{
                backgroundColor: '#DEDEDE',
                borderColor: '#DEDEDE',
                color: 'black',
                width: '100%',
                height: '45px',
                borderRadius: '15px',
              }}
            >
              Sign up With Google
            </Button>

            {/* Sign in button */}
            <Button
              variant='outlined'
              onClick={() => navigate('/client-login', { replace: true })}
              sx={{
                margin: 2,
                width: '100%',
                height: '45px',
                borderRadius: '15px',
                borderColor: 'black',
                color: 'white',
                backgroundColor: 'black',
                '&:hover': {
                  backgroundColor: 'gray',
                  borderColor: 'gray',
                },
              }}
            >
              I already have an account
            </Button>
          </CustomCardContainer>
        </CustomContainer>
      </div>
    </ThemeProvider>
  );
};
