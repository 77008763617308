import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress, styled, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Appbar } from '../../components';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import firebaseApi from '../../../backend/firebase/FirebaseApi';
import { useSelector } from 'react-redux';
import { RootState } from '../../../backend/redux/store';
import { base } from '../../../backend/utils/BaseUrl';

export const TrainerProfileSessions: React.FC = () => {
  const navigate = useNavigate();
  const [sessions, setSessions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const handleBackClick = () => {
    navigate(-1);
  };

  const uid = useSelector((state: RootState) => state.user.uid);

  useEffect(() => {
    console.log('Current logged-in user ID:', uid);

    const fetchSessions = async () => {
      if (!uid) {
        setError('User ID not found');
        setLoading(false);
        return;
      }

      try {
        const response = await firebaseApi.getSessionsByTrainer(base, uid);
        const sessionsData = response as any[];
        console.log('Fetched sessions:', sessionsData);
        console.log('Type of fetched sessions:', typeof sessionsData);

        if (sessionsData.length === 0) {
          setError('You do not have any previous sessions.');
          setLoading(false);
          return;
        }

        const parseDate = (dateString: string): Date => {
          return new Date(dateString.replace(/-/g, '/'));
        };

        const formattedSessions = sessionsData.map((session) => {
          let formattedDate;

          if (typeof session.date === 'string') {
            formattedDate = parseDate(session.date).toLocaleDateString('en-US', {
              month: '2-digit',
              day: '2-digit',
              year: 'numeric',
            });
          } else {
            formattedDate = 'Invalid date';
          }
          return {
            ...session,
            date: new Date(session.date),
            dateTime: formattedDate,
          };
        });

        const sortedSessions = formattedSessions.sort((a, b) => b.date.getTime() - a.date.getTime());

        console.log('Sorted sessions:', sortedSessions);
        setSessions(sortedSessions);
      } catch (err) {
        console.error('Error fetching sessions:', err);
        setError('You do not have any previous sessions.');
      } finally {
        setLoading(false);
      }
    };

    fetchSessions();
  }, [uid]);

  if (loading) {
    return (
      <ProfileContainer>
        <CircularProgress />
      </ProfileContainer>
    );
  }

  return (
    <>
      <Appbar showMenu={true} showCloseIcon={true} />
      <BackButton onClick={handleBackClick}>
        <ArrowBackIcon sx={{ fontSize: '30px', fontWeight: 'bold', color: 'black' }} />
      </BackButton>
      <ProfileContainer>
        <Typography
          variant='h4'
          component='h1'
          sx={{ fontWeight: 'bold', fontSize: '1.5rem', marginBottom: '30px', textAlign: 'center' }}
        >
          Previous Sessions
        </Typography>
        {error ? (
          <Typography color='error'>{error}</Typography>
        ) : (
          sessions.length > 0 &&
          sessions.map((session) => (
            <SessionCard key={session.sessionID}>
              <Box>
                <Typography sx={{ fontSize: '0.8rem' }}>Session ID: {session.sessionID}</Typography>
                <Typography>Date: {session.dateTime}</Typography>
              </Box>
              <SessionPrice>${session.payment.toFixed(2)}</SessionPrice>
            </SessionCard>
          ))
        )}
      </ProfileContainer>
    </>
  );
};

const ProfileContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  paddingTop: '0px',
  width: '90%',
  margin: '0 auto',
}));

const SessionCard = styled(Box)(({ theme }) => ({
  background: '#DEDEDE',
  borderRadius: '15px',
  padding: '10px',
  marginBottom: '1.5rem',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const SessionPrice = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
}));

const BackButton = styled(IconButton)(({ theme }) => ({
  marginTop: '2%',
  marginLeft: '5%',
  [theme.breakpoints.down('sm')]: {
    marginTop: '5%',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    marginTop: '4%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginTop: '3%',
  },
}));
