import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CallControls,
  CallingState,
  StreamCall,
  StreamTheme,
  StreamVideo,
  StreamVideoClient,
  useCallStateHooks,
  useParticipantViewContext,
  User,
  VideoPlaceholderProps,
  ParticipantView,
} from '@stream-io/video-react-sdk';
import styled from 'styled-components';
import '@stream-io/video-react-sdk/dist/css/styles.css';
import './style.css';
import myApi, { FirebaseApiResponse } from '../../../backend/firebase/FirebaseApi';
import { base } from '../../../backend/utils/BaseUrl';
import { OnboardingSessionInfo, OnboardingTrainerInfo } from '../../../backend';
import { app } from '../../../backend/firebase/FirebaseConfig';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`;

const CountdownTimer = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  z-index: 1000;
  border: 1px solid orange;
`;

// Duration Mapping
const durationMapping: { [key: string]: number } = {
  '30 minutes': 33 * 60 * 1000,
  '15 minutes': 18 * 60 * 1000,
  '45 minutes': 47 * 60 * 1000,
  '60 minutes': 63 * 60 * 1000,
  '1 minutes': 0.5 * 60 * 1000,
};

// Function to check if the screen size is a laptop
const isLaptopScreen = () => {
  const laptopMinWidth = 1024;
  return window.innerWidth >= laptopMinWidth;
};

const getName = (name: string) => {
  if (!name) return 'Unknown';
  const nameParts = name.split('-');
  return nameParts[0] + ' ' + nameParts[1];
};

// Main Component
export const VideoPage: React.FC = () => {
  const apiKey = 'xefauu9f36qp';
  const sessionID = sessionStorage.getItem('sessionID');
  const isAgent = sessionStorage.getItem('Agent');
  const navigate = useNavigate();
  const token = sessionStorage.getItem('token') || 'any';
  const userId = sessionStorage.getItem('userId') || 'any';
  const callId = sessionStorage.getItem('callId');

  const [client, setClient] = useState<StreamVideoClient | null>(null);
  const [call, setCall] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [remainingTime, setRemainingTime] = useState(durationMapping[sessionStorage.getItem('time') || '30 minutes']);
  const [isLaptop, setIsLaptop] = useState(isLaptopScreen());

  const clientRef = useRef<StreamVideoClient | null>(null);
  const callRef = useRef<any>(null);
  const firestore = getFirestore(app);
  const trainerSessionRef = doc(firestore, 'sessions', sessionStorage.getItem('sessionID')!);

  // Time Formatter
  const formatTime = (ms: number) => {
    const minutes = Math.floor(ms / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  const makePayMent = (cost: number, id: string) => {
    const session: OnboardingTrainerInfo = {
      earnings: cost,
    };
    myApi.updateSingleTrainer(base, id, session).then((res: FirebaseApiResponse) => {
      if (typeof res === 'string' || (res as any).error) {
        console.error(res);
      } else {
        console.log(res);
      }
    });
  };

  // Check Screen Size
  const checkScreenSize = () => {
    setIsLaptop(isLaptopScreen());
  };

  useEffect(() => {
    window.addEventListener('resize', checkScreenSize);
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(trainerSessionRef, (doc: any) => {
      const sessionData = doc.data();
      if (sessionData?.status === 'left') {
        if (!sessionStorage.getItem('status')) {
          if (callRef.current) callRef.current.leave();
          if (clientRef.current) clientRef.current.disconnectUser();
          makePayMent(sessionData.payment, sessionData.trainerId);
          sessionStorage.setItem('status', 'paid');
        }
        // setTimeout(() => {
        if (isAgent === 'client') {
          navigate('/trainer-tip');
        } else if (isAgent === 'trainer') {
          navigate('/trainer-rating');
        }
        // }, 1000);
      } else if (sessionData?.status != 'matched') {
        if (isAgent === 'client') {
          navigate('/trainer-tip');
        } else if (isAgent === 'trainer') {
          navigate('/trainer-rating');
        }
      }
    });
    return () => {
      unsubscribe();
    };
  }, [sessionID]);

  useEffect(() => {
    let isMounted = true;

    const initializeClient = async () => {
      try {
        const user: User = {
          id: userId,
          name: userId || 'User',
        };

        const newClient = new StreamVideoClient({ apiKey, user, token });
        setClient(newClient);
        clientRef.current = newClient;

        const newCall = newClient.call('default', callId!);
        await newCall.join({ create: true });
        setCall(newCall);
        callRef.current = newCall;

        if (isMounted) setLoading(false);
      } catch (err) {
        console.error('Error initializing video call:', err);
        if (isMounted) setError(err instanceof Error ? err.message : 'An unknown error occurred');
        if (isMounted) setLoading(false);
      }
    };
    if (!sessionStorage.getItem('status')) {
      initializeClient();
    }
    const interval = setInterval(() => {
      setRemainingTime((prev) => {
        if (prev <= 1000) {
          clearInterval(interval);
          if (isAgent === 'client') {
            if (callRef.current) callRef.current.leave();
            if (clientRef.current) clientRef.current.disconnectUser();

            leaveCall(navigate);
          }

          return 0;
        }
        return prev - 1000;
      });
    }, 1000);

    return () => {
      isMounted = false;
      if (call) call.leave();
      if (client) client.disconnectUser();
      clearInterval(interval);
    };
  }, [apiKey, callId, navigate, token, userId]);

  const leaveCall = (navigate: (path: string) => void) => {
    const session: OnboardingSessionInfo = {
      status: 'left',
    };

    sessionStorage.setItem('status', 'left');

    myApi.updateStatus(base, sessionID!, session).then((res: FirebaseApiResponse) => {
      if (typeof res === 'string' || (res as any).error) {
        console.error(res);
      } else {
        console.log(res);
      }
    });
  };

  const MyUILayout = useCallback(() => {
    const { useCallCallingState, useParticipants, useLocalParticipant } = useCallStateHooks();
    const callingState = useCallCallingState();
    const participants = useParticipants();
    const localParticipant = useLocalParticipant();

    const [secondaryPos, setSecondaryPos] = useState({ x: 0, y: 0 });
    const isDraggingRef = useRef(false);
    const secondaryViewRef = useRef<HTMLDivElement>(null);

    const remoteParticipants = participants.filter((p) => p.sessionId !== localParticipant?.sessionId);

    const mainParticipant = remoteParticipants[0];
    const secondaryParticipant = localParticipant;

    const handleMouseDown = (e: React.MouseEvent) => {
      isDraggingRef.current = true;
    };

    const handleMouseMove = (e: React.MouseEvent) => {
      if (!isDraggingRef.current || !secondaryViewRef.current) return;

      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const { width, height } = secondaryViewRef.current.getBoundingClientRect();

      let newX = secondaryPos.x + e.movementX;
      let newY = secondaryPos.y + e.movementY;

      if (newX < -viewportWidth + width) newX = -viewportWidth + width;
      if (newX > 0) newX = 0;
      if (newY < -viewportHeight + height) newY = -viewportHeight + height;
      if (newY > 0) newY = 0;

      setSecondaryPos({ x: newX, y: newY });
    };

    const handleMouseUp = () => {
      isDraggingRef.current = false;
    };

    useEffect(() => {
      const handleGlobalMouseMove = (e: MouseEvent) => {
        handleMouseMove(e as any);
      };

      document.addEventListener('mouseup', handleMouseUp);
      document.addEventListener('mousemove', handleGlobalMouseMove);
      return () => {
        document.removeEventListener('mouseup', handleMouseUp);
        document.removeEventListener('mousemove', handleGlobalMouseMove);
      };
    }, [secondaryPos]);

    if (callingState !== CallingState.JOINED) {
      return <LoadingContainer>Loading... Current state: {callingState}</LoadingContainer>;
    }

    return (
      <StreamTheme>
        <div className='main-view'>
          {mainParticipant && (
            <>
              <div className='participant-name'>{getName(mainParticipant.name)}</div>
              <ParticipantView
                participant={mainParticipant}
                VideoPlaceholder={CustomVideoPlaceholder}
                ParticipantViewUI={ParticipantDetails}
              />
            </>
          )}
        </div>
        {secondaryParticipant && (
          <div
            ref={secondaryViewRef}
            className='secondary-view'
            style={{ transform: `translate(${secondaryPos.x}px, ${secondaryPos.y}px)` }}
            onMouseDown={handleMouseDown}
          >
            <div className='participant-name'>{getName(secondaryParticipant.name)}</div>
            <ParticipantView
              participant={secondaryParticipant}
              VideoPlaceholder={CustomVideoPlaceholder}
              ParticipantViewUI={ParticipantDetails}
            />
          </div>
        )}
        <CallControls onLeave={() => leaveCall(navigate)} />
      </StreamTheme>
    );
  }, [navigate]);

  if (loading) {
    return <LoadingContainer>Initializing video call...</LoadingContainer>;
  }

  if (error) {
    return <LoadingContainer>Error: {error}</LoadingContainer>;
  }

  if (!client || !call) {
    return <LoadingContainer>Missing client or call information</LoadingContainer>;
  }

  return (
    <StreamVideo client={client}>
      <StreamCall call={call}>
        <MyUILayout />
        <CountdownTimer>{formatTime(remainingTime)}</CountdownTimer>
      </StreamCall>
    </StreamVideo>
  );
};

const ParticipantDetails = () => {
  const { participant } = useParticipantViewContext();

  return (
    <div className='participant-name' title={participant.name}>
      <span>{getName(participant.name)}</span>
    </div>
  );
};

const CustomVideoPlaceholder = ({ style }: VideoPlaceholderProps) => {
  const { participant } = useParticipantViewContext();

  return <div className='video-placeholder'>{getName(participant.name)}</div>;
};
