import React, { useEffect, useState } from 'react';
import { Appbar, Customcard, Map } from '../../components';
import { Avatar, Box, Paper, Typography, Alert, Collapse, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { Star as StarIcon, Close as CloseIcon } from '@mui/icons-material';
import myApi, { FirebaseApiResponse } from '../../../backend/firebase/FirebaseApi';
import { base } from '../../../backend/utils/BaseUrl';
import { OnboardingSessionInfo } from '../../../backend/models/Data';

export const TrainerWaitPage: React.FC = () => {
  const [counter, setCounter] = useState(30);
  const [clientInfo, setClientInfo] = useState({
    firstName: '',
    lastInitial: '',
    rating: 0,
    gender: '',
    age: 0,
    profilePhoto: '',
  });

  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const navigate = useNavigate();
  const sessionID = sessionStorage.getItem('sessionID');

  const calculateAge = (birthdate: string): number => {
    const birthDate = new Date(birthdate);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const getLastInitial = (lastName: string): string => {
    return lastName.charAt(0).toUpperCase() + '.';
  };

  const fetchClientInfo = (clientID: string) => {
    myApi.readSingleClient(base, clientID.toString()).then((res: FirebaseApiResponse) => {
      if (typeof res === 'string' || (res as any).error) {
        console.error(res);
        setAlertMessage('An error occurred while fetching client information. Please try again.');
        setAlertOpen(true);
      } else {
        let data: any = res;
        setClientInfo({
          firstName: data.firstName,
          lastInitial: getLastInitial(data.lastName),
          rating: data.rating,
          gender: data.gender,
          age: calculateAge(data.dob),
          profilePhoto:
            data.profilePhoto || data.firstName.charAt(0).toUpperCase() + data.lastName.charAt(0).toUpperCase(),
        });
      }
    });
  };

  useEffect(() => {
    const clientID = sessionStorage.getItem('clientID');
    if (clientID) {
      fetchClientInfo(clientID);
    } else {
      setAlertMessage('An error occurred while fetching session information. Please try again.');
      setAlertOpen(true);
    }
  }, []);

  const updateStatus = (id: string, status: string) => {
    const data: OnboardingSessionInfo = { status };

    myApi.updateStatus(base, id, data).then((res: FirebaseApiResponse) => {
      if (typeof res === 'string' || (res as any).error) {
        console.error('Error:', res);
        setAlertMessage('An error occurred while updating your session status. Please try again.');
        setAlertOpen(true);
      } else {
        console.log('Status updated successfully:', res);
      }
    });
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (sessionID) {
        updateStatus(sessionID, 'rejected');
        event.preventDefault();
        event.returnValue = 'error';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    const timer = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter === 1) {
          clearInterval(timer);
          sessionStorage.setItem('Agent', 'trainer');
          navigate('/video-session');
        }
        return prevCounter - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigate, sessionID]);

  return (
    <>
      <Appbar showMenu={false} userRole='trainer' />
      <ParentContainer>
        <Customcard>
          <Box display='flex' justifyContent='space-between'>
            <Typography
              variant='h6'
              align='center'
              sx={{ fontWeight: 'bold', marginBottom: 5, marginLeft: 1, marginTop: 1, width: '65%' }}
            >
              Video call starting soon, be prepared!
            </Typography>
            <CounterBox>
              <Typography variant='h6'>{counter} sec</Typography>
            </CounterBox>
          </Box>
          <Box display='flex' flexDirection='column' alignItems='center' mb={2}>
            <Box display='flex' alignItems='center'>
              <Avatar
                src={clientInfo.profilePhoto}
                alt={`${clientInfo.firstName} ${clientInfo.lastInitial}`}
                sx={{ width: 112, height: 100, marginRight: 2 }}
              />
              <Box sx={{ marginLeft: 2 }}>
                <Typography variant='h6'>
                  {clientInfo.firstName} {clientInfo.lastInitial}
                </Typography>
                <Typography variant='body1'>
                  {clientInfo.rating.toFixed(2)}{' '}
                  <StarIcon style={{ color: 'white', verticalAlign: '-8%', fontSize: '15px' }} />
                </Typography>
                <Typography variant='body2'>
                  {clientInfo.gender}, {clientInfo.age}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box display='flex' justifyContent='center'>
            <WarningMessage>
              Please wait until the video call begins, and do not leave or refresh this page!
            </WarningMessage>
          </Box>

          <Collapse in={alertOpen}>
            <Alert
              severity='error'
              action={
                <IconButton aria-label='close' color='inherit' size='small' onClick={() => setAlertOpen(false)}>
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
              sx={{ mt: 2 }}
            >
              {alertMessage}
            </Alert>
          </Collapse>
        </Customcard>
        <Map />
      </ParentContainer>
    </>
  );
};

const ParentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  backgroundColor: 'black',
}));

const CounterBox = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
  marginRight: theme.spacing(2),
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '20%',
  height: '50px',
}));

const WarningMessage = styled(Typography)(({ theme }) => ({
  color: 'red',
  padding: theme.spacing(2),
  textAlign: 'center',
}));
