import { useState, useEffect } from 'react';
import { Typography, Box, Button, Alert, Collapse, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { Appbar, Customcard as BaseCustomCard, Map } from '../../components';
import { Star as StarIcon, Close as CloseIcon } from '@mui/icons-material';
import myApi, { FirebaseApiResponse } from '../../../backend/firebase/FirebaseApi';
import { base } from '../../../backend/utils/BaseUrl';
import { OnboardingSessionInfo } from '../../../backend/models/Data';
import { useSelector } from 'react-redux';
import { RootState } from '../../../backend/redux/store';
import { useNavigate } from 'react-router-dom';
import { app } from '../../../backend/firebase/FirebaseConfig';
import { getFirestore, doc, onSnapshot } from 'firebase/firestore';

export const TrainerAcceptPage = () => {
  const [counter, setCounter] = useState(60);
  const [price, setPrice] = useState(0);
  const [sessionLength, setSessionLength] = useState('');
  const [rating, setRating] = useState(0);
  const trainerID = useSelector((state: RootState) => state.user.uid);
  const navigate = useNavigate();
  const object = sessionStorage.getItem('sessionID');
  const firestore = getFirestore(app);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  const trainerSessionRef = doc(firestore, 'sessions', sessionStorage.getItem('sessionID')!);
  const updateStatus = (id: string, status: string) => {
    const data: OnboardingSessionInfo = { status };

    myApi.updateStatus(base, id, data).then((res: FirebaseApiResponse) => {
      if (typeof res === 'string' || (res as any).error) {
        console.error('Error:', res);
        setAlertMessage('An error occurred while updating your session status. Please try again.');
        setAlertOpen(true);
      } else {
        console.log('Status updated successfully:', res);
      }
    });
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (object) {
        updateStatus(object, 'closed');
        event.preventDefault();
        event.returnValue = 'error';
        setAlertMessage('You have exited this page, you will be redirected ');
        setAlertOpen(true);
        sessionStorage.clear();
        navigate('/trainer-home');
      }
    };

    const unsubscribe = onSnapshot(trainerSessionRef, (doc: any) => {
      const sessionData = doc.data();
      if (sessionData?.status === 'matched') {
        myApi.readSingleSession(base, sessionStorage.getItem('sessionID')!).then((res: FirebaseApiResponse) => {
          if (typeof res === 'string' || (res as any).error) {
            console.error(res);
            setAlertMessage('An error occurred while fetching session data. Please try again.');
            setAlertOpen(true);
          } else {
            let data: any = res;
            console.log(data);
            sessionStorage.setItem('clientID', data.clientID);
            myApi.getVideoData(base, sessionStorage.getItem('sessionID')!).then((res: any) => {
              if (typeof res === 'string' || (res as any).error) {
                console.error(res);
              } else {
                const { clientToken, trainerToken, callingID, clientUserId, trainerUserId, time } = res;
                // sessionStorage.getItem('userId')
                sessionStorage.setItem('userId', trainerUserId);
                sessionStorage.setItem('callId', callingID);
                sessionStorage.setItem('token', trainerToken);
                sessionStorage.setItem('time', time);
              }
            });

            navigate('/trainer-wait');
          }
        });
      }
    });
    window.addEventListener('beforeunload', handleBeforeUnload);

    const timer = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter === 1) {
          clearInterval(timer);
          updateStatus(sessionStorage.getItem('sessionID')!, 'expired');
          navigate('/trainer-home');
        }
        return prevCounter - 1;
      });
    }, 1000);

    return () => {
      unsubscribe();
      clearInterval(timer);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigate, object]);

  useEffect(() => {
    let object = sessionStorage.getItem('sessionResData')!;
    if (object) {
      let data: any = JSON.parse(object);
      sessionStorage.setItem('sessionID', data.sessionId);
      setPrice(data.payment);
      setSessionLength(data.sessionType);
      myApi.readSingleClient(base, data.clientID.toString()).then((res: FirebaseApiResponse) => {
        if (typeof res === 'string' || (res as any).error) {
          console.error(res);
          setAlertMessage('An error occurred while fetching client data. Please try again.');
          setAlertOpen(true);
        } else {
          let data: any = res;
          setRating(data.rating);
        }
      });
    } else {
      setAlertMessage('An error occurred while fetching session data. Please try again.');
      setAlertOpen(true);
    }
  }, []);

  const handleAcceptClick = () => {
    // check if client still exists via api check

    myApi.readSingleSession(base, sessionStorage.getItem('sessionID')!).then((res: FirebaseApiResponse) => {
      if (typeof res === 'string' || (res as any).error) {
        console.error(res);
        setAlertMessage('There was an issue with reading this client info. Try again');
        setAlertOpen(true);
        sessionStorage.removeItem('sessionID');
        sessionStorage.removeItem('sessionResData');
        sessionStorage.setItem('decline', 'true');
        navigate('/trainer-home');
      } else {
        let data: any = res;
        let data2: OnboardingSessionInfo = {
          trainerId: trainerID!,
          trainerAccepted: true,
        };
        myApi
          .updateTrainerSession(base, sessionStorage.getItem('sessionID')!, data2)
          .then((res: FirebaseApiResponse) => {
            if (typeof res === 'string' || (res as any).error) {
              console.error(res);
              setAlertMessage('An error occurred while updating session data. Please try again.');
              setAlertOpen(true);
            } else {
              console.log(res);
              // handle success UI
              // navigate('/train');
            }
          });
      }
    });
  };

  const handleCloseClick = () => {
    console.log('Close button clicked');
    let sessionID = sessionStorage.getItem('sessionID');
    if (sessionID) {
      let data: OnboardingSessionInfo = {
        trainerId: trainerID!,
        trainerAccepted: false!,
      };

      myApi.updateTrainerSession(base, sessionID, data).then((res: FirebaseApiResponse) => {
        if (typeof res === 'string' || (res as any).error) {
          console.log('error', res);
          setAlertMessage('An error occurred while updating session data. Please try again.');
          setAlertOpen(true);
          sessionStorage.removeItem('sessionID');
          sessionStorage.removeItem('sessionResData');
          sessionStorage.setItem('decline', 'true');
          navigate('/trainer-home');
        } else {
          console.log(res);
          // handle success UI
          setAlertOpen(false);
          sessionStorage.removeItem('sessionID');
          sessionStorage.removeItem('sessionResData');
          sessionStorage.setItem('decline', 'true');
          navigate('/trainer-home');
        }
      });
    }
  };

  return (
    <>
      <Appbar showMenu={false} userRole='trainer' />
      <ParentContainer>
        <Customcard>
          <ButtonContainer>
            <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
              <Box flex={1} display='flex' justifyContent='center'>
                <Price sx={{ marginLeft: '2.7rem' }}>
                  {price}
                  <img
                    src='/moneybag_white.png'
                    alt='Money Bag'
                    style={{ marginLeft: '8px', width: '30px', height: '30px', color: 'white' }}
                  />
                </Price>
              </Box>
              <img
                src='/close.png'
                alt='CloseRoundedIcon'
                style={{
                  cursor: 'pointer',
                  width: '45px',
                  height: '45px',
                  color: 'white',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                }}
                onClick={handleCloseClick}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'scale(1.1)';
                  e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'scale(1)';
                  e.currentTarget.style.boxShadow = 'none';
                }}
              />
            </Box>
            <PriceInfo>(before expected tips)</PriceInfo>
            <RatingButton>
              <Typography variant='body1' component='span' style={{ marginRight: '0.25rem' }}>
                {rating.toFixed(2)}
              </Typography>
              <StarIcon style={{ fontSize: '1rem', color: 'black' }} />
            </RatingButton>
          </ButtonContainer>

          <Divider />

          <SessionInfoContainer>
            <VerticalDivider />
            <SessionInfo>
              <Typography variant='body1' component='span' style={{ display: 'block' }}>
                {sessionLength} Session
              </Typography>
              <OnlineText>Online</OnlineText>
            </SessionInfo>
          </SessionInfoContainer>
          <InfoButtonsContainer>
            <AcceptButton onClick={handleAcceptClick}>Accept</AcceptButton>
            <Timer>{counter} seconds left</Timer>
          </InfoButtonsContainer>

          <Collapse in={alertOpen}>
            <Alert
              severity='error'
              action={
                <IconButton color='inherit' size='small' onClick={() => setAlertOpen(false)}>
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
              sx={{ mt: 2 }}
            >
              {alertMessage}
            </Alert>
          </Collapse>
        </Customcard>
        <Map />
      </ParentContainer>
    </>
  );
};

const ParentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100vh',
  width: '100%',
  backgroundColor: 'black',
}));

const Customcard = styled(BaseCustomCard)(({ theme }) => ({
  marginBottom: '2rem',
  maxWidth: '90%',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '1rem',
  },
  [theme.breakpoints.up('md')]: {
    marginBottom: '3rem',
  },
}));

const Price = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  font: 'Oswald, sans-serif',
  fontWeight: 'bold',
  color: '#FFFFFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
}));

const PriceInfo = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: '#F27C22',
  textAlign: 'center',
}));

const RatingButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  font: 'Oswald, sans-serif',
  justifyContent: 'center',
  fontSize: '0.8rem',
  color: '#000000',
  backgroundColor: '#FFFFFF',
  borderRadius: '20px',
  padding: '0.25rem 0.5rem',
  textAlign: 'center',
  marginTop: '0.5rem',
  minWidth: '3rem',
}));

const SessionInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start', // Align to the left
  marginTop: '1.25rem',
  color: '#FFFFFF',
  width: '100%',
}));

const SessionInfo = styled('div')(({ theme }) => ({
  color: '#FFFFFF',
  font: 'Oswald, sans-serif',
  textAlign: 'left',
}));

const OnlineText = styled(Typography)(({ theme }) => ({
  color: '#8B8787',
  display: 'block',
  font: 'Oswald, sans-serif',
  fontSize: '1rem',
}));

const AcceptButton = styled(Button)(({ theme }) => ({
  height: '3rem',
  borderRadius: '60px',
  font: 'Oswald, sans-serif',
  backgroundColor: '#F27C22',
  color: '#FFFFFF',
  fontSize: '1.6rem',
  '&:hover': {
    backgroundColor: '#d06d20',
  },
  flex: 1,
  marginRight: '1rem',
  textTransform: 'none',
}));

const Divider = styled('div')(({ theme }) => ({
  border: '1px solid #8B8787',
  width: '100%',
  height: '0px',
  marginTop: '1rem',
  opacity: '1',
}));

const VerticalDivider = styled('div')(({ theme }) => ({
  borderLeft: '1px solid #8B8787',
  height: '3rem',
  marginRight: '1rem',
}));

const Timer = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  font: 'Oswald, sans-serif',
  fontWeight: 'bold',
  color: '#000000',
  backgroundColor: '#FFFFFF',
  borderRadius: '0.5rem',
  padding: '0.25rem 0.5rem',
  textAlign: 'center',
  height: '3rem',
  display: 'flex',
  alignItems: 'center',
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
}));

const InfoButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  gap: '1rem',
  marginTop: '1rem',
}));
