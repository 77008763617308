import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import { Appbar } from '../../components';

export const NoPage = () => {
  return (
    <div>
      {/* AppBar */}
      <Appbar showMenu={true} />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyItems: 'center',
        }}
      >
        <h1> ERROR 404: Page Not Found </h1>
      </div>
    </div>
  );
};
