import axios, { AxiosError } from 'axios';
import type { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { OnboardingClientInfo, OnboardingTrainerInfo, OnboardingSessionInfo, Tip } from '../models';

export type FirebaseApiResponse =
  | OnboardingClientInfo
  | OnboardingClientInfo[]
  | OnboardingTrainerInfo
  | OnboardingTrainerInfo[]
  | OnboardingSessionInfo
  | OnboardingSessionInfo[]
  | Tip

const doCall = async (request: AxiosRequestConfig): Promise<FirebaseApiResponse> => {
  const { method, url, headers: reqHeaders, data = {}, ...rest } = request;

  if (!url) {
    throw new AxiosError('Missing url');
  }

  const config = { ...rest, headers: { ...reqHeaders } };
  let res: AxiosResponse<FirebaseApiResponse>;

  switch (method?.toLowerCase()) {
    case 'post':
      res = await axios.post(url, data, config);
      break;
    case 'get':
      res = await axios.get(url, config);
      break;
    case 'put':
      res = await axios.put(url, data, config);
      break;
    case 'delete':
      res = await axios.delete(url, { ...config, data });
      break;
    default:
      res = await axios.get(url, config);
      break;
  }

  return res.data;
};

export default {
    // https://us-central1-gyfr-mvp.cloudfunctions.net/firestoreApi
  // Client
  readAllClients: (baseUrl: string): Promise<FirebaseApiResponse> => {
    const request = { method: 'get' as Method, url: `${baseUrl}/client` };
    return doCall(request).catch((err) => []);
  },

  readSingleClient: (baseUrl: string, id: string): Promise<FirebaseApiResponse> => {
    const request = { method: 'get' as Method, url: `${baseUrl}/client/${id}` };
    return doCall(request).catch((err) => err.response?.data ?? err.message);
  },

  createSingleClient: (baseUrl: string, client: OnboardingClientInfo): Promise<FirebaseApiResponse> => {
    const request = {
      method: 'post' as Method,
      url: `${baseUrl}/client`,
      data: client,
    };
    return doCall(request).catch((err) => err.response?.data ?? err.message);
  },

  updateSingleClient: (
    baseUrl: string,
    id: string,
    clientUpdates: OnboardingClientInfo
  ): Promise<FirebaseApiResponse> => {
    const request = {
      method: 'put' as Method,
      url: `${baseUrl}/client/${id}`,
      data: clientUpdates,
    };
    return doCall(request).catch((err) => err.response?.data ?? err.message);
  },

  deleteSingleClient: (baseUrl: string, id: string): Promise<FirebaseApiResponse> => {
    const request = { method: 'delete' as Method, url: `${baseUrl}/client/${id}` };
    return doCall(request).catch((err) => err.response?.data ?? err.message);
  },

  // Trainer
  readAllTrainers: (baseUrl: string): Promise<FirebaseApiResponse> => {
    const request = { method: 'get' as Method, url: `${baseUrl}/trainer` };
    return doCall(request).catch((err) => []);
  },

  readSingleTrainer: (baseUrl: string, id: string): Promise<FirebaseApiResponse> => {
    const request = { method: 'get' as Method, url: `${baseUrl}/trainer/${id}` };
    return doCall(request).catch((err) => err.response?.data ?? err.message);
  },

  createSingleTrainer: (baseUrl: string, trainer: OnboardingTrainerInfo): Promise<FirebaseApiResponse> => {
    const request = {
      method: 'post' as Method,
      url: `${baseUrl}/trainer`,
      data: trainer,
    };
    return doCall(request).catch((err) => err.response?.data ?? err.message);
  },

  updateSingleTrainer: (
    baseUrl: string,
    id: string,
    trainerUpdates: OnboardingTrainerInfo
  ): Promise<FirebaseApiResponse> => {
    const request = {
      method: 'put' as Method,
      url: `${baseUrl}/trainer/${id}`,
      data: trainerUpdates,
    };
    return doCall(request).catch((err) => err.response?.data ?? err.message);
  },

  deleteSingleTrainer: (baseUrl: string, id: string): Promise<FirebaseApiResponse> => {
    const request = { method: 'delete' as Method, url: `${baseUrl}/trainer/${id}` };
    return doCall(request).catch((err) => err.response?.data ?? err.message);
  },

  payoutSingleTrainer: (baseUrl: string, id: string): Promise<FirebaseApiResponse> => {
    const request = { method: 'get' as Method, url: `${baseUrl}/trainer/${id}/payout` };
    return doCall(request).catch((err) => err.response?.data ?? err.message);
  },

  payoutNewLink: (baseUrl: string, id: string): Promise<FirebaseApiResponse> => {
    const request = { method: 'get' as Method, url: `${baseUrl}/trainer/check/link${id}` };
    return doCall(request).catch((err) => err.response?.data ?? err.message);
  },

  addTrainerTip: (baseUrl: string, id: string,tip: Tip): Promise<FirebaseApiResponse> => {
    const request = { method: 'put' as Method, url: `${baseUrl}/tip/${id}` };
    return doCall(request).catch((err) => err.response?.data ?? err.message);
  },



  // sessions onboarding

  readSingleSession: (baseUrl: string, id: string): Promise<FirebaseApiResponse> => {
    const request = { method: 'get' as Method, url: `${baseUrl}/session/${id}` };
    return doCall(request).catch((err) => err.response?.data ?? err.message);
  },

  readAllSessions: (baseUrl: string): Promise<FirebaseApiResponse> => {
    const request = { method: 'get' as Method, url: `${baseUrl}/session/` };
    return doCall(request).catch((err) => []);
  },

  // add client in session and default info
  createSingleSession: (baseUrl: string, session: OnboardingSessionInfo): Promise<FirebaseApiResponse> => {
    const request = {
      method: 'post' as Method,
      url: `${baseUrl}/session`, // clientID, cost, sessionType
      data: session,
    };
    return doCall(request).catch((err) => err.response?.data ?? err.message);
  },

  setTrainerSession: (baseUrl: string, session: OnboardingSessionInfo): Promise<FirebaseApiResponse> => {
    const request = {
      method: 'post' as Method,
      url: `${baseUrl}/trainer/available`, // only trainerID in body
      data: session,
    };
    return doCall(request).catch((err) => err.response?.data ?? err.message);
  },


  updateTrainerSession: (baseUrl: string, sessionId: string,session: OnboardingSessionInfo): Promise<FirebaseApiResponse> => {
    const request = {
      method: 'post' as Method,
      url: `${baseUrl}/sessions/${sessionId}/trainer-response`, // only trainerAccepted in body
      data: session,
    };
    return doCall(request).catch((err) => err.response?.data ?? err.message);
  },

  deleteSingleSessionCondition: (baseUrl: string, id: string): Promise<FirebaseApiResponse> => {
    const request = { method: 'delete' as Method, url: `${baseUrl}/session/${id}` };
    return doCall(request).catch((err) => err.response?.data ?? err.message);
  },

   updateStatus: (baseUrl: string, sessionId: string,session: OnboardingSessionInfo): Promise<FirebaseApiResponse> => {
    const request = {
      method: 'put' as Method,
      url: `${baseUrl}/sessions/${sessionId}/status`, // only status in body 
      data: session,
    };
    return doCall(request).catch((err) => err.response?.data ?? err.message);
  },



  getSessionsByTrainer: (baseUrl: string, id: string): Promise<FirebaseApiResponse> => {
    const request = { method: 'get' as Method, url: `${baseUrl}/sessions/trainer/${id}` };
    return doCall(request).catch((err) => err.response?.data ?? err.message);
  },

  getSessionsByClient: (baseUrl: string, id: string): Promise<FirebaseApiResponse> => {
    const request = { method: 'get' as Method, url: `${baseUrl}/sessions/client/${id}` };
    return doCall(request).catch((err) => err.response?.data ?? err.message);
  },

  getVideoData: (baseUrl: string, id: string): Promise<any> => {
    const request = { method: 'get' as Method, url: `${baseUrl}/session/${id}/video` };
    return doCall(request).catch((err) => err.response?.data ?? err.message);
  }



};
