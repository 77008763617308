import {
  Close as CloseIcon,
  Delete as DeleteIcon,
  KeyboardBackspace as KeyboardBackspaceIcon,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  ButtonProps,
  Checkbox,
  Collapse,
  Container,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  styled,
  Link,
} from '@mui/material';
import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Appbar, CustomStepper, GradientButton } from '../../components';
import { TrainerInfo } from './TrainerInfo';
import { handleValidateTrainer } from '../../../backend/utils/HandleValidate';
import { OnboardingTrainerInfo } from '../../../backend/models/Data';
import { RootState } from '../../../backend/redux/store';
import { useSelector } from 'react-redux';
import { ref, uploadBytes, getDownloadURL, deleteObject, StorageReference } from 'firebase/storage';
import { storage } from '../../../backend/firebase/FirebaseConfig';
import { getAuth } from 'firebase/auth';

export const TrainerExperiencePage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [trainerInfo, setTrainerInfo] = useState<TrainerInfo>(() => {
    const savedInfo = localStorage.getItem('trainerInfo');
    return savedInfo
      ? JSON.parse(savedInfo)
      : {
          firstName: '',
          lastName: '',
          phone: '',
          gender: '',
          dateOfBirth: '',
          identificationType: '',
          identityFrontImageURL: '',
          identityBackImageURL: '',
          identityFaceImageURL: '',
          consistency: '',
          before: '',
          textValue: '',
          certText: '',
          certificationImageURL: '',
          termsAccepted: false,
        };
  });
  const [step, setStep] = useState<number>(location.state?.step || 0);
  const [consistencyErr, setConsistencyErr] = useState(false);
  const [beforeErr, setBeforeErr] = useState(false);
  const [textErr, setTextErr] = useState(false);
  const [showAlert, setShowAlert] = useState<string>('');
  const [isImageUploaded, setIsImageUploaded] = useState<boolean>(
    !!localStorage.getItem(trainerInfo.certificationImageURL)
  );
  const uid = useSelector((state: RootState) => state.user.uid);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setTrainerInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setTrainerInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setTrainerInfo((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const storageRef = ref(storage, `trainers/${uid}/certificationImage`);
      try {
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);

        setIsImageUploaded(true);
        localStorage.setItem('certification_imageURL', downloadURL);
        setTrainerInfo((prevState) => ({
          ...prevState,
          certificationImageURL: downloadURL || '',
        }));
      } catch (error) {
        console.log('Error uploading image: ', error);
        setShowAlert('Error uploading image. Please try again.');
      }
    }
  };

  const handleDelete = async () => {
    const imageRef = ref(storage, `trainers/${uid}/certificationImage`);
    localStorage.removeItem('certification_imageURL');
    setTrainerInfo((prevState) => ({
      ...prevState,
      certificationImageURL: '',
    }));
    setIsImageUploaded(false);
    try {
      await deleteObject(imageRef);
      console.log('Certification image deleted from Firebase Storage');
    } catch (error) {
      console.error('Error deleting certification image:', error);
      setShowAlert('Error deleting certification image. Please try again.');
    }
  };

  useEffect(() => {
    localStorage.setItem('trainerInfo', JSON.stringify(trainerInfo));
  }, [trainerInfo]);

  useEffect(() => {
    const storedInfo = localStorage.getItem('trainerInfo');
    if (storedInfo) {
      const parsedInfo = JSON.parse(storedInfo);
      setTrainerInfo(parsedInfo);
      setIsImageUploaded(!!parsedInfo.certificationImageURL);
    }
  }, []);

  const handleBack = () => {
    if (step === 0) {
      navigate('/trainer-identity', {
        state: { step: location.state?.fromIdentityStep || 0 },
      });
    } else {
      setStep(0);
      setShowAlert('');
      setConsistencyErr(false);
      setBeforeErr(false);
      setTextErr(false);
    }
  };

  const formatDateToMMDDYYYY = (dateString: string) => {
    // Split the date string into month, day, and year
    const dateParts = dateString.split('/');
    let month = dateParts[0];
    let day = dateParts[1];
    const year = dateParts[2];

    // Pad month and day with leading zeros if necessary
    month = month.padStart(2, '0');
    day = day.padStart(2, '0');

    // Return the formatted date string in mm/dd/yyyy format
    return `${month}/${day}/${year}`;
  };

  const handleContinue = () => {
    setConsistencyErr(!trainerInfo.consistency);
    setBeforeErr(!trainerInfo.before);
    setTextErr(!trainerInfo.textValue || trainerInfo.textValue.length === 0 || trainerInfo.textValue.length > 800);

    const valid =
      trainerInfo.consistency &&
      trainerInfo.before &&
      trainerInfo.textValue &&
      trainerInfo.textValue.length > 0 &&
      trainerInfo.textValue.length <= 800;

    const valid2 = trainerInfo.termsAccepted;

    if (step === 0 && valid) {
      setStep(1);
      setShowAlert('');
      setConsistencyErr(false);
      setBeforeErr(false);
      setTextErr(false);
      console.log(trainerInfo.dateOfBirth);
    } else if (step === 1 && valid2) {
      const auth = getAuth();
      const userEmail = auth.currentUser?.email;
      if (!userEmail) {
        setShowAlert('Unable to retrieve your email. Please try again.');
        return;
      }
      const data: OnboardingTrainerInfo = {
        trainerID: uid!,
        email: userEmail,
        firstName: trainerInfo.firstName,
        lastName: trainerInfo.lastName,
        phoneNumber: trainerInfo.phone,
        gender: trainerInfo.gender,
        dob: formatDateToMMDDYYYY(trainerInfo.dateOfBirth),
        rating: 5,
        certificationImageURL: localStorage.getItem('certification_imageURL') || 'none',
        IDImage:
          // localStorage.getItem('identity_front_imageURL') || localStorage.getItem('identity_back_imageURL') || 'none',
          [localStorage.getItem('identity_front_imageURL') || '', localStorage.getItem('identity_back_imageURL')]
            .filter(Boolean)
            .join(' | ') || '',
        UserPhoto: localStorage.getItem('identity_face_imageURL') || '',
        consistency: trainerInfo.consistency,
        trainBefore: trainerInfo.before,
        adaptTraining: trainerInfo.textValue,
        certText: trainerInfo.certText || '',
      };

      handleValidateTrainer(data, navigate, (errorMessage: string) => {
        if (errorMessage && typeof errorMessage !== 'string') {
          errorMessage = JSON.stringify(errorMessage);
        }
        setShowAlert(errorMessage);
      });
    } else {
      let errorMessage = 'Please answer all the question(s) to proceed!';
      if (step === 1 && !valid2) {
        errorMessage = 'Please agree with our policies above to submit!';
      }
      setShowAlert(errorMessage);
    }
  };

  return (
    <div>
      <Appbar showMenu={true} />

      <CustomDiv>
        <CustomStepper activeStep={2} />

        <BackButtonContainer>
          <IconButton onClick={handleBack}>
            <KeyboardBackspaceIcon sx={{ color: '#F27C22', fontSize: 30 }} />
          </IconButton>
        </BackButtonContainer>

        {step === 0 ? (
          <>
            <CustomContainer>
              <CustomHeading>
                Great, last few steps now! Answer the following questions concisely and accurately:
              </CustomHeading>
              <OrderedList>
                <li style={numberedListStyle}>
                  <Questions>How long have you been consistently working out for?</Questions>
                  <QuestionOptions required error={consistencyErr} size='small'>
                    <SelectOption
                      displayEmpty
                      name='consistency'
                      value={trainerInfo.consistency}
                      onChange={(e) => handleSelectChange(e as SelectChangeEvent<string>)}
                      renderValue={(selected: unknown): ReactNode => {
                        if (!selected) {
                          return <em></em>;
                        }
                        return selected as string;
                      }}
                    >
                      <MenuItem disabled value=''></MenuItem>
                      <MenuItem value='0-1 Years'>0-1 Years</MenuItem>
                      <MenuItem value='1-2 Years'>1-2 Years</MenuItem>
                      <MenuItem value='2-4 Years'>2-4 Years</MenuItem>
                      <MenuItem value='4+ Years'>4+ Years</MenuItem>
                    </SelectOption>
                  </QuestionOptions>
                </li>

                <li style={numberedListStyle}>
                  <Questions>Have you, formally or informally, provided training to anyone else before?</Questions>
                  <QuestionOptions required error={beforeErr} size='small'>
                    <SelectOption
                      displayEmpty
                      name='before'
                      value={trainerInfo.before}
                      onChange={(e) => handleSelectChange(e as SelectChangeEvent<string>)}
                      renderValue={(selected: unknown): ReactNode => {
                        if (!selected) {
                          return <em></em>;
                        }
                        return selected as string;
                      }}
                    >
                      <MenuItem disabled value=''></MenuItem>
                      <MenuItem value='Yes'>Yes</MenuItem>
                      <MenuItem value='No'>No</MenuItem>
                    </SelectOption>
                  </QuestionOptions>
                </li>

                <li style={numberedListStyle}>
                  <Questions>
                    Describe in 2-3 sentences how you would adapt your workouts to different individuals' needs and
                    fitness levels?
                  </Questions>
                  <Box
                    component='textarea'
                    name='textValue'
                    value={trainerInfo.textValue}
                    onChange={handleInputChange}
                    sx={{
                      marginTop: 1,
                      marginLeft: '-4.5%',
                      width: '94%',
                      height: '100px',
                      padding: '10px',
                      borderRadius: '15px',
                      fontFamily: 'roboto',
                      outline: 'none',
                      border: `3px solid ${textErr ? '#d32f2f' : '#000'}`,
                      '&:focus': {
                        border: '3px solid #000',
                      },
                    }}
                  />
                  <Typography variant='body2' color='textSecondary'>
                    {trainerInfo.textValue?.length || 0}/800 characters
                  </Typography>
                </li>
              </OrderedList>
              <CustomCollapse in={!!showAlert}>
                <Alert
                  severity='error'
                  action={
                    <IconButton
                      aria-label='close'
                      color='inherit'
                      size='small'
                      onClick={() => {
                        setShowAlert('');
                      }}
                    >
                      <CloseIcon fontSize='inherit' />
                    </IconButton>
                  }
                >
                  {showAlert}
                </Alert>
              </CustomCollapse>

              <GradientButton showArrowIcon text='Continue' handleClick={handleContinue} styles={ContinueStyle} />
            </CustomContainer>
          </>
        ) : (
          <>
            <CustomContainer>
              <CustomHeading2>Do you possess any personal training certifications?</CustomHeading2>
              <Description>
                While we do not require our personal trainers to hold any certifications, trainers with certifications
                may receive higher-paying offers and access to surge bonuses.
              </Description>
              <CertBox>
                <Questions>If you possess any certifications, list them below.</Questions>

                <Box
                  component='textarea'
                  name='certText'
                  value={trainerInfo.certText}
                  onChange={handleInputChange}
                  sx={{
                    marginTop: 1,
                    marginBottom: 2,
                    padding: '10px', // Added padding for spacing
                    borderRadius: '15px',
                    fontFamily: 'sans-serif',
                    border: '3px solid #000',
                    width: '90%',
                    height: '100px',
                  }}
                />

                <Questions>
                  Upload a clear picture of any certifications you have listed above. If you hold multiple
                  certifications, upload a picture of your highest accredited certification.
                </Questions>

                <UploadBox>
                  <StyledUploadButton variant='contained'>
                    Upload Here!
                    <input
                      type='file'
                      accept='image/*'
                      hidden
                      onChange={(e) => handleImageUpload(e)}
                      key={Date.now()}
                    />
                  </StyledUploadButton>

                  {isImageUploaded && <UploadedImageStatus onDelete={() => handleDelete()} />}
                </UploadBox>

                <TermsForm
                  control={
                    <Checkbox
                      checked={trainerInfo.termsAccepted}
                      name='termsAccepted'
                      onChange={handleCheckboxChange}
                      sx={{
                        color: '#000',
                        '&.Mui-checked': {
                          color: '#000',
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: 26,
                        },
                      }}
                    />
                  }
                  label={
                    <span>
                      I agree with all the{' '}
                      <Link
                        href='https://gyfrapp.com/policies/terms-of-service'
                        target='_blank'
                        rel='noopener noreferrer'
                        sx={{ color: '#F27C22' }}
                      >
                        terms of services
                      </Link>{' '}
                      and{' '}
                      <Link
                        href='https://gyfrapp.com/policies/privacy-policy'
                        target='_blank'
                        rel='noopener noreferrer'
                        sx={{ color: '#F27C22' }}
                      >
                        privacy policies
                      </Link>{' '}
                      of Gyfr.
                    </span>
                  }
                />
              </CertBox>
              <CustomCollapse in={!!showAlert}>
                <Alert
                  severity='error'
                  action={
                    <IconButton
                      aria-label='close'
                      color='inherit'
                      size='small'
                      onClick={() => {
                        setShowAlert('');
                      }}
                    >
                      <CloseIcon fontSize='inherit' />
                    </IconButton>
                  }
                >
                  {showAlert}
                </Alert>
              </CustomCollapse>

              <GradientButton text='Submit!' handleClick={handleContinue} styles={ContinueStyle} />
            </CustomContainer>
          </>
        )}
      </CustomDiv>
    </div>
  );
};

const CustomDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  width: '100%',
  paddingTop: '2%',
  [theme.breakpoints.down('sm')]: {
    paddingTop: '15px',
  },
  [theme.breakpoints.up('lg')]: {
    paddingTop: '20px',
  },
}));

const BackButtonContainer = styled(Container)(({ theme }) => ({
  marginLeft: '0%',
  [theme.breakpoints.between('md', 'lg')]: {
    marginLeft: '10%',
    width: '60%',
  },
  [theme.breakpoints.up('lg')]: {
    marginLeft: '20%',
    width: '50%',
  },
}));

const CustomContainer = styled(Container)(({ theme }) => ({
  width: '50%',
  flexDirection: 'column',
  alignContent: 'center',
  display: 'flex',
  marginLeft: '25%',
  [theme.breakpoints.down('sm')]: {
    width: '80%',
    marginLeft: '10%',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    width: '70%',
    marginLeft: '15%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '50%',
    marginLeft: '25%',
  },
}));

const CustomHeading = styled(Typography)(({ theme }) => ({
  marginTop: '5%',
  marginLeft: '6%',
  marginBottom: '9%',
  color: '#F27C22',
  fontFamily: 'Inter',
  fontSize: '27px',
  lineHeight: '30.26px',
  height: '33px',
  fontWeight: 'bold',
  [theme.breakpoints.down('sm')]: {
    marginTop: '0%',
    marginLeft: '5%',
    marginBottom: '130px',
    fontSize: '24px',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginBottom: '20%',
    marginLeft: '8%',
  },
  [theme.breakpoints.up('lg')]: {
    marginBottom: '80px',
    marginLeft: '8%',
    width: '90%',
  },
}));

const OrderedList = styled('ol')(({ theme }) => ({
  marginLeft: '-5%',
  [theme.breakpoints.between('sm', 'md')]: {
    marginLeft: '3.5%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginLeft: '3.5%',
  },
  [theme.breakpoints.up('lg')]: {
    marginLeft: '4.5%',
  },
}));

const Questions = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  width: '90%',
}));

const QuestionOptions = styled(FormControl)(({ theme }) => ({
  marginTop: '5px',
  width: '100%',
  marginBottom: '15px',
  marginLeft: '-5%',
  [theme.breakpoints.between('sm', 'md')]: {
    marginLeft: '-5%',
  },
  [theme.breakpoints.up('md')]: {
    marginLeft: '-4.5%',
  },
}));

const SelectOption = styled(Select)(({ theme }) => ({
  marginTop: '5px',
  borderRadius: '15px',
  marginBottom: 2,
  height: '39px',
  minWidth: '90%',
  '& .MuiOutlinedInput-notchedOutline': {
    border: '3px solid #000',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '3px solid #000',
  },
}));

const numberedListStyle: CSSProperties = {
  fontFamily: 'roboto',
  fontWeight: 'bold',
};

const CustomHeading2 = styled(Typography)(({ theme }) => ({
  marginTop: '5%',
  marginLeft: '8%',
  marginBottom: '8%',
  color: '#F27C22',
  fontFamily: 'Inter',
  fontSize: '27px',
  lineHeight: '30.26px',
  height: '33px',
  fontWeight: 'bold',
  [theme.breakpoints.down('sm')]: {
    marginTop: '0%',
    marginLeft: '4%',
    marginBottom: '90px',
    fontSize: '24px',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginBottom: '15%',
    marginLeft: '8%',
  },
  [theme.breakpoints.up('lg')]: {
    marginBottom: '70px',
    marginLeft: '8%',
  },
}));

const Description = styled(Typography)(({ theme }) => ({
  color: '#797A79',
  fontSize: '15px',
  lineHeight: '22.23px',
  marginBottom: theme.spacing(2),
  maxWidth: '90%',
  marginLeft: '8%',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '4%',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    marginLeft: '8%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginLeft: '8%',
    marginBottom: '1%',
  },
}));

const CustomCollapse = styled(Collapse)(({ theme }) => ({
  marginLeft: '5%',
  width: '95%',
  marginTop: '4%',
  marginBottom: '2%',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '4%',
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: '2%',
  },
}));

const ContinueStyle: CSSProperties = {
  maxWidth: '95%',
  marginLeft: '5%',
  marginBottom: '5%',
};

const CertBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginLeft: '4%',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    marginLeft: '8%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginLeft: '8%',
  },
  [theme.breakpoints.up('lg')]: {
    marginLeft: '8%',
  },
}));

const StyledUploadButton: React.FC<ButtonProps> = (props) => (
  <UploadButton component='label' {...props}>
    {props.children}
  </UploadButton>
);

const UploadButton = styled(Button)(({ theme }) => ({
  width: 'wrap-content',
  height: 'wrap-content',
  borderRadius: '15px',
  padding: '10px 40px',
  backgroundColor: '#000',
  fontWeight: 'bold',
  textTransform: 'none',
  fontSize: '15px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
  },
}));

const UploadBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignContent: 'center',
  marginTop: '3%',
  marginBottom: '4%',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '10%',
  },
}));

interface UploadedImageStatusProps {
  onDelete: () => void;
}

const UploadedImageStatus: React.FC<UploadedImageStatusProps> = ({ onDelete }) => (
  <>
    <StyledTypography>Image uploaded</StyledTypography>
    <IconButton onClick={onDelete} color='error'>
      <DeleteIcon />
    </IconButton>
  </>
);

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginTop: '13px',
  fontSize: '15px',
  [theme.breakpoints.down('sm')]: {
    marginLeft: '5%',
    fontSize: '10px',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    marginLeft: '3%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginLeft: '3%',
  },
  [theme.breakpoints.up('lg')]: {
    marginLeft: '2%',
  },
}));

const TermsForm = styled(FormControlLabel)(({ theme }) => ({
  alignItems: 'center',
  '& .MuiFormControlLabel-label': {
    fontSize: '12px',
    fontWeight: 'bold',
  },
}));
