import React, { CSSProperties, useEffect, useState } from 'react';
import { Typography, Box, Button, Alert, Collapse, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { Appbar, Customcard, Map } from '../../components';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BackHandIcon from '@mui/icons-material/BackHand';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import myApi, { FirebaseApiResponse } from '../../../backend/firebase/FirebaseApi';
import { base } from '../../../backend/utils/BaseUrl';
import { OnboardingSessionInfo } from '../../../backend/models/Data';
import { useSelector } from 'react-redux';
import { RootState } from '../../../backend/redux/store';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

export const TrainerHomePage = () => {
  const trainerID = useSelector((state: RootState) => state.user.uid);
  const navigate = useNavigate();

  if (!sessionStorage.getItem('isOnline')) {
    sessionStorage.setItem('isOnline', JSON.stringify(false));
    console.log('false');
  }

  const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
  const [isDeclined, setIsDeclined] = useState(false);
  const [isOnline, setIsOnline] = useState(sessionStorage.getItem('isOnline') === 'true' ? true : false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [retryTimeout, setRetryTimeout] = useState<NodeJS.Timeout | null>(null);

  const handleExternalLink = (url: string) => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    if (isDeclined) {
      navigate('/trainer-accept');
    }
  }, [isDeclined, navigate]);

  useEffect(() => {
    sessionStorage.setItem('isOnline', JSON.stringify(isOnline));
  }, [isOnline]);

  useEffect(() => {
    const decline = sessionStorage.getItem('decline');

    if (decline === 'true') {
      sessionStorage.removeItem('decline');
      const session: OnboardingSessionInfo = {
        trainerId: trainerID!,
      };

      handleSessionRequest(session);
    }
  }, [trainerID]);

  const handleSessionRequest = async (session: OnboardingSessionInfo) => {
    const retryDelay = 30000;

    const attemptRequest = async () => {
      const res: FirebaseApiResponse = await myApi.setTrainerSession(base, session);

      if (typeof res === 'string' || (res as any).error) {
        console.log('Error: ', res);
        setAlertMessage('There are no suitable training sessions at the moment. This might take 10-20 minutes.');
        const alertTimeout = setTimeout(() => {
          setAlertOpen(true);
        }, 10000);
        const timeout = setTimeout(() => {
          attemptRequest();
        }, retryDelay);
        setRetryTimeout(timeout);

        return () => {
          clearTimeout(timeout);
          clearTimeout(alertTimeout);
        };
      } else {
        console.log(res);
        let x: any = JSON.stringify(res);
        sessionStorage.setItem('sessionID', x.sessionId);
        sessionStorage.setItem('sessionResData', JSON.stringify(res));
        await delay(2000);
        setIsDeclined(true);
      }
    };

    attemptRequest();
  };

  const whenOnline = () => {
    setIsOnline(true);
    const session: OnboardingSessionInfo = {
      trainerId: trainerID!,
    };
    handleSessionRequest(session);
  };

  const stopShift = () => {
    setIsOnline(false);
    setAlertOpen(false);

    if (retryTimeout) {
      clearTimeout(retryTimeout);
    }
  };

  return (
    <>
      <Appbar showMenu={false} userRole='trainer' />
      <ParentContainer>
        <Customcard>
          <Typography variant='h1' style={h1Style}>
            {isOnline ? "You're Online!" : 'Hey! Ready to start earning?'}
          </Typography>
          {isOnline ? (
            <ButtonContainer>
              <StateChangeButton
                variant='contained'
                style={{ backgroundColor: '#FF00008F', color: '#DEDEDE' }}
                onClick={stopShift}
                endIcon={<BackHandIcon />}
              >
                <span style={{ flex: 1, marginLeft: 16, textAlign: 'center' }}>Stop My Shift!</span>
              </StateChangeButton>
              <Typography style={{ marginTop: '1rem', color: '#FFFFFF', fontSize: '1rem' }}>
                Stay tuned and wait to receive training session requests.
              </Typography>
            </ButtonContainer>
          ) : (
            <ButtonContainer>
              <StateChangeButton
                variant='contained'
                style={{ backgroundColor: '#148C3D', color: '#DEDEDE' }}
                onClick={whenOnline}
                endIcon={<ArrowForwardIosIcon />}
              >
                <span style={{ flex: 1, marginLeft: 16, textAlign: 'center' }}>Begin My Shift!</span>
              </StateChangeButton>
              <InfoButtonsContainer>
                <InfoButton
                  variant='contained'
                  style={{
                    backgroundColor: '#D9D9D9',
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={() => navigate('/trainer-payout')}
                  startIcon={
                    <img
                      src='/moneybag_black.png'
                      alt='CloseRoundedIcon'
                      style={{ cursor: 'pointer', width: '30px', height: '30px', color: 'black' }}
                    />
                  }
                >
                  Show me my earnings
                </InfoButton>
                <InfoButton
                  variant='contained'
                  style={{
                    backgroundColor: '#D9D9D9',
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={() => handleExternalLink('https://gyfrapp.com/pages/personal-trainer-guide')}
                  startIcon={<QuestionMarkIcon style={{ fontSize: '2rem' }} />}
                >
                  How does it work?
                </InfoButton>
              </InfoButtonsContainer>
            </ButtonContainer>
          )}

          <Collapse in={alertOpen}>
            <Alert
              severity='error'
              action={
                <IconButton color='inherit' size='small' onClick={() => setAlertOpen(false)}>
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
              sx={{ mt: 2 }}
            >
              {alertMessage}
            </Alert>
          </Collapse>
        </Customcard>
        <Map />
      </ParentContainer>
    </>
  );
};

const h1Style: CSSProperties = {
  font: 'roboto',
  color: '#F27C22',
  fontSize: '1.3rem',
  fontWeight: '500',
  marginBottom: '1rem',
  fontFamily: 'Inter',
  textAlign: 'center',
  padding: '0.5rem 1rem',
  borderRadius: '10px',
  marginTop: '0.5rem',
  textTransform: 'none',
};

const ParentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  backgroundColor: 'black',
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
}));

const InfoButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '90%',
  gap: '1rem',
}));

const StateChangeButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0.5rem 1rem',
  fontFamily: 'Oswald, sans-serif',
  fontSize: '1.2rem',
  textAlign: 'center',
  borderRadius: '15px',
  width: '90%',
  marginBottom: '0.5rem',
  textTransform: 'none',
  '& .MuiButton-endIcon': {
    marginLeft: 'auto',
  },
  '& .MuiButton-startIcon': {
    marginRight: 'auto',
  },
}));

const InfoButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '1rem',
  lineHeight: '1rem',
  fontFamily: 'Inter, sans-serif',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1rem',
  color: 'black',
  borderRadius: '15px',
  textTransform: 'none',
  marginTop: '1rem',
  width: '90%',
  flex: 1,
  '& .MuiButton-startIcon': {
    margin: 0,
  },
}));
