import { useEffect, useRef, useState } from 'react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import React from 'react';
import { styled } from '@mui/system';

const MapContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  width: '99vw',
  overflow: 'hidden',
  position: 'relative',

  [theme.breakpoints.between('sm', 'md')]: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

const MapStyle = styled('div')(({ theme }) => ({
  flex: 1,
  width: '100%',
  height: '100%',
  overflow: 'hidden',

  [theme.breakpoints.up('md')]: {
    width: '70%',
  },
}));

export const MyMapComponent = React.memo(() => {
  const [map, setMap] = useState<google.maps.Map>();
  const ref = useRef<HTMLDivElement>(null);
  const [markerCluster, setMarkerCluster] = useState<MarkerClusterer>();
  const [marker, setMarker] = useState<{ lat: number; lng: number } | undefined>();

  useEffect(() => {
    if (ref.current && !map) {
      if (navigator.geolocation) {
        console.log('geolocation is supported');
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };

            const mapInstance = new window.google.maps.Map(ref.current as HTMLDivElement, {
              center: userLocation,
              zoom: 16,
              streetViewControl: false,
              mapTypeControl: false,
              mapId: 'b850543640575632', // Ensure this is the correct mapId
            });

            setMap(mapInstance);
            setMarker({ lat: userLocation.lat, lng: userLocation.lng });
            setMarkerCluster(new MarkerClusterer({ map: mapInstance, markers: [] }));
          },
          () => {
            console.log('geolocation is not supported');

            // Fallback to default location if geolocation fails
            const defaultLocation = { lat: 4.4333479181711075, lng: -75.21505129646759 };
            const mapInstance = new window.google.maps.Map(ref.current as HTMLDivElement, {
              center: defaultLocation,
              zoom: 16,
              streetViewControl: false,
              mapTypeControl: false,
              mapId: 'b850543640575632', // Ensure this is the correct mapId
            });

            setMap(mapInstance);
            setMarker({ lat: defaultLocation.lat, lng: defaultLocation.lng });
            setMarkerCluster(new MarkerClusterer({ map: mapInstance, markers: [] }));
          }
        );
      } else {
        console.log('geolocation is not supported');

        // Fallback to default location if geolocation is not supported
        const defaultLocation = { lat: 4.4333479181711075, lng: -75.21505129646759 };
        const mapInstance = new window.google.maps.Map(ref.current as HTMLDivElement, {
          center: defaultLocation,
          zoom: 16,
          streetViewControl: false,
          mapTypeControl: false,
          mapId: 'b850543640575632', // Ensure this is the correct mapId
        });

        setMap(mapInstance);
        setMarker({ lat: defaultLocation.lat, lng: defaultLocation.lng });
        setMarkerCluster(new MarkerClusterer({ map: mapInstance, markers: [] }));
      }
    }
  }, [map]);

  useEffect(() => {
    const addMarkers = async () => {
      const { AdvancedMarkerElement } = (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary;
      if (marker && markerCluster) {
        const markerElement = document.createElement('div');
        markerElement.style.width = '50px';
        markerElement.style.height = '50px';
        markerElement.style.backgroundImage = 'url("../../image_blu.png")';
        markerElement.style.backgroundSize = 'cover';
        markerElement.style.transform = 'translate(-50%, -50%)'; // Center the icon

        const advancedMarkerElement = new AdvancedMarkerElement({
          position: new google.maps.LatLng(marker.lat, marker.lng),
          map: map!,
          content: markerElement,
          zIndex: 9999,
        });

        markerCluster.addMarker(advancedMarkerElement);
      }
    };

    addMarkers();
  }, [marker, markerCluster, map]);

  return (
    <>
      <MapContainer>
        <MapStyle ref={ref}></MapStyle>
      </MapContainer>
    </>
  );
});
