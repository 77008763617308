import React, { useState } from 'react';
import { styled } from '@mui/system';
import { Card, CardContent, CardActions, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapsible from 'react-collapsible';

interface CustomCardProps {
  children: React.ReactNode;
  actions?: React.ReactNode;
}

const CustomCardContainer = styled(Card)(({ theme }) => ({
  zIndex: 800,
  backgroundColor: 'black',
  color: 'white',
  overflow: 'hidden',
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  width: '100%',
  borderRadius: '35px 35px 0 0',
  boxShadow: '0 -4px 10px rgba(0,0,0,0.2)',
  animation: 'slideUp 0.5s ease-out',
  '@keyframes slideUp': {
    '0%': {
      transform: 'translateY(100%)',
    },
    '100%': { 
      transform: 'translateY(0)',
    },
  },

  [theme.breakpoints.down('sm')]: {
    maxHeight: '80vh',
  },

  [theme.breakpoints.between('sm', 'md')]: {
    maxHeight: '90vh',
  },

  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: '860px',
    margin: 'auto',
    width: '50%',
    maxWidth: '400px',
    borderRadius: '25px 25px 25px 25px',
    maxHeight: '460px',

  },

}));

const Content = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

const Actions = styled(CardActions)(({ theme }) => ({
  justifyContent: 'flex-start',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

const DownButtonContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  marginTop: '-28px',
  background: 'linear-gradient(90deg, rgba(140, 20, 20, 0.86) 0%, #F27C22 100%)',
  borderRadius: '20%',
  zIndex: 1, 

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },

}));

export const Customcard = ({ children, actions }: CustomCardProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <CustomCardContainer>
      <DownButtonContainer>
        <IconButton aria-label='expand more' onClick={handleToggle}>
          <ExpandMoreIcon style={{ color: 'white', transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 0.3s' }} />
        </IconButton>
      </DownButtonContainer>
      <Collapsible
        open={isOpen}
        transitionTime={400}
        trigger={<span><br></br></span>} 
        triggerWhenOpen={<span></span>}
      >
        <Content>{children}</Content>
        {actions && <Actions>{actions}</Actions>}
      </Collapsible>
    </CustomCardContainer>
  );
};

// export  Customcard;
