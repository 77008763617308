import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Box, Card, CardContent, Radio, Button, Alert, Collapse, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { Appbar, Map, GradientButton, Customcard as BaseCustomCard } from '../../components';
import { ArrowBack as ArrowBackIcon, Close as CloseIcon } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import myApi, { FirebaseApiResponse } from '../../../backend/firebase/FirebaseApi';
import { base } from '../../../backend/utils/BaseUrl';
import { OnboardingSessionInfo } from '../../../backend/models/Data';
import { useSelector } from 'react-redux';
import { RootState } from '../../../backend/redux/store';

interface Session {
  id: string;
  length: number;
  price: number;
  eta: number;
  link: string;
}

interface SessionCardProps {
  title: string;
  estimatedWaitTime: string;
  price: string;
  onClick: () => void;
  selected: boolean;
}

const sessionOptions: Session[] = [
  { id: '1', length: 15, price: 6.99, eta: 5, link: 'https://buy.stripe.com/9AQ28cbBMbuBd1u9AE' },
  { id: '2', length: 30, price: 11.99, eta: 5, link: 'https://buy.stripe.com/00g4gkbBMgOV3qUfZ1' },
  { id: '3', length: 45, price: 15.99, eta: 3, link: 'https://buy.stripe.com/3cs0047lw0PX3qUdQS' },
  { id: '4', length: 60, price: 19.99, eta: 2, link: 'https://buy.stripe.com/dR6bIM6hs4297HacMM' },
];

const SessionCard: React.FC<SessionCardProps> = ({ title, estimatedWaitTime, price, onClick, selected }) => (
  <PriceCard onClick={onClick} selected={selected}>
    <CardContent
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
        pr: 4.5,
        pl: 1,
        py: 1,
      }}
    >
      <Box sx={{ mt: -0.3, mb: -1.7, display: 'flex', alignItems: 'center' }}>
        <Radio
          checked={selected}
          sx={{
            color: 'white',
            '&.Mui-checked': {
              color: 'white',
            },
          }}
        />
        <Box>
          <Typography variant='body1' sx={{ color: 'white', fontWeight: 'bold', fontSize: '18px' }}>
            {title}
          </Typography>
          <Typography variant='body2' sx={{ color: 'white', fontWeight: 'bold', fontSize: '15px' }}>
            ETA: {estimatedWaitTime}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 1.5, textAlign: 'right' }}>
        <Typography variant='body1' sx={{ color: 'white', fontWeight: 'bold', fontSize: '20px' }}>
          {price}
        </Typography>
      </Box>
    </CardContent>
  </PriceCard>
);

export const HomePageSession: React.FC = () => {
  const [selectedSession, setSelectedSession] = useState<Session | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uid = useSelector((state: RootState) => state.user.uid);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  // const id = useSelector((state: RootState) => state.user.sessionID);

  useEffect(() => {
    const savedSession = localStorage.getItem('selectedSession');
    if (savedSession) {
      setSelectedSession(JSON.parse(savedSession));
    }

    checkSessionID();
  }, []);

  const checkSessionID = () => {
    let id = sessionStorage.getItem('sessionID');
    if (id) {
      myApi.readSingleSession(base, id).then((res: FirebaseApiResponse) => {
        if (typeof res === 'string' || (res as any).error) {
          console.log('Error:', res);
          setAlertMessage('An error occurred while fetching your session data. Please try again.');
          setAlertOpen(true);
        } else {
          let data: any = res;
          if (data.status == 'waiting') {
            setAlertMessage(
              'Our records indicate that you have already paid for a session. Please wait for a trainer to accept your session.'
            );
            setAlertOpen(true);
            navigate('/find-trainer');
          } else if (data.status == 'pending_payment') {
            setAlertMessage(
              'Our records indicate that you have already chosen a session option recently. Since payment was not completed, we will allow you to select a new one.'
            );
            setAlertOpen(true);
            deleteSessions(id!);
            sessionStorage.removeItem('sessionID');
          }
        }
      });
    }
  };

  const deleteSessions = (id: string) => {
    myApi.deleteSingleSessionCondition(base, id).then((res: FirebaseApiResponse) => {
      if (typeof res === 'string' || (res as any).error) {
        console.log('Error:', res);
        setAlertMessage('An error occurred while deleting your session data. Please try again.');
        setAlertOpen(true);
      } else {
        console.log(res);
      }
    });
  };

  const handleSessionClick = (session: Session) => {
    setSelectedSession(session);
    localStorage.setItem('selectedSession', JSON.stringify(session));
  };

  const handleBackClick = () => {
    navigate('/home-page');
  };

  const handleGradientButtonClick = () => {
    // add this back after hosting
    // if (selectedSession) {
    //   // Save the selected session details to local storage
    //   localStorage.setItem('selectedSessionDetails', JSON.stringify(selectedSession));
    //   window.location.href = selectedSession.link;
    // }

    const data: OnboardingSessionInfo = {
      clientID: uid!,
      cost: selectedSession?.price,
      sessionType: selectedSession?.length.toString() + ' minutes',
    };

    myApi.createSingleSession(base, data).then((res: FirebaseApiResponse) => {
      if (typeof res === 'string' || (res as any).error) {
        console.error('Error', res);
        setAlertMessage('An error occurred while creating your session. Please try again.');
        setAlertOpen(true);
      } else {
        let data: any = res;
        sessionStorage.setItem('sessionID', data.sessionID);
        if (selectedSession) {
          // Save the selected session details to local storage
          localStorage.setItem('selectedSessionDetails', JSON.stringify(selectedSession));
          window.location.href = selectedSession.link;
        }
        // navigate('/find-trainer');
      }
    });
  };

  return (
    <>
      <Appbar showMenu={false} userRole='client' />
      <ParentContainer>
        <Customcard>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
            <Button variant='text' onClick={handleBackClick} sx={{ position: 'absolute', left: 0 }}>
              <ArrowBackIcon sx={{ marginRight: '7px', fontSize: '30px', color: 'white' }} />
            </Button>
            <Typography
              variant='h5'
              sx={{
                textAlign: 'center',
                mb: 2,
                marginTop: '1rem',
                color: 'white',
                fontWeight: 'bold',
                fontSize: '25px',
              }}
            >
              Choose Session Length
            </Typography>
          </Box>
          {sessionOptions.map((session) => (
            <SessionCard
              key={session.id}
              title={`${session.length} Minute Session`}
              estimatedWaitTime={`${session.eta} min`}
              price={`$${session.price.toFixed(2)}`}
              onClick={() => handleSessionClick(session)}
              selected={selectedSession?.id === session.id}
            />
          ))}
          <Divider />

          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: '1.2rem', mb: '10px' }}>
            <GradientButton
              text={selectedSession ? `Choose ${selectedSession.length} Minute Session` : 'Choose a Session'}
              handleClick={handleGradientButtonClick}
              showArrowIcon={false}
              styles={{
                width: '90%',
              }}
            />
          </Box>

          <Collapse in={alertOpen}>
            <Alert
              severity='error'
              action={
                <IconButton aria-label='close' color='inherit' size='small' onClick={() => setAlertOpen(false)}>
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
              sx={{ mt: 2 }}
            >
              {alertMessage}
            </Alert>
          </Collapse>
        </Customcard>
        <Map />
      </ParentContainer>
    </>
  );
};

const PriceCard = styled(Card)<{ selected: boolean }>(({ theme, selected }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(0),
  background: selected ? '#4E84C14D' : 'black',
  cursor: 'pointer',
  '&:hover': {
    background: '#4E84C14D',
  },
}));

const ParentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  backgroundColor: 'black',
}));

const Divider = styled('div')({
  border: '1px solid #8B8787',
  width: '100%',
  height: '0px',
  marginTop: '0.5rem',
  opacity: '1',
});

const Customcard = styled(BaseCustomCard)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  position: 'relative',
  backgroundColor: 'black',
  color: 'white',
  maxHeight: '400px',
  overflowY: 'auto',
  [theme.breakpoints.down('sm')]: {
    maxHeight: '80vh',
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(2),
    maxHeight: '100vh',
    maxWidth: '600px',
    alignSelf: 'center',
  },
}));
