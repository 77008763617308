import React from 'react';
import { CSSProperties } from 'react';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { MyMapComponent } from './MapContainer';
// import {KEY_MAP} from '../../../backend/config/config'

// const customMapStyles:any x;

const render = (status: Status) => <h1>{status}</h1>;

export const Map = () => {
  return (
    <div className='map'>
      <Wrapper apiKey={process.env.REACT_APP_MAP_API_KEY} render={render}>
        <MyMapComponent />
      </Wrapper>
    </div>
  );
};
