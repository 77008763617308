import React from 'react';
import { Box, ListItemButton, ListItemText, styled, Divider, Typography, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  ArrowBack as ArrowBackIcon,
  QuestionMark as QuestionMarkIcon,
  SupportAgent as SupportIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  ThumbsUpDown,
} from '@mui/icons-material';
import { Appbar } from '../../components';

const BackButton = styled(IconButton)(({ theme }) => ({
  marginTop: '2%',
  marginLeft: '5%',
  [theme.breakpoints.down('sm')]: {
    marginTop: '5%',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    marginTop: '4%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginTop: '3%',
  },
}));

const FirstTitle = styled(Typography)(({ theme }) => ({
  fontSize: '30px',
  fontWeight: 'bold',
  textAlign: 'left',
  marginTop: '1%',
  marginLeft: '7%',
  [theme.breakpoints.down('sm')]: {
    marginTop: '3%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginTop: '1%',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '30px',
  fontWeight: 'bold',
  textAlign: 'left',
  marginTop: '2%',
  marginLeft: '7%',
  [theme.breakpoints.down('sm')]: {
    marginTop: '5%',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    marginTop: '4%',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    marginTop: '3%',
  },
}));

const CustomListItemText = styled(ListItemText)(({ theme }) => ({
  marginLeft: 13,
  textAlign: 'left',
  fontWeight: 'bold',
}));

const iconStyles = {
  display: 'flex',
  alignItems: 'center',
  color: 'black',
  width: '26px',
  height: '26px',
};

const IconBox = styled(Box)(({ theme }) => ({
  marginLeft: '7%',
  display: 'flex',
  alignItems: 'center',
}));

const ArrowIconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: 'black',
  marginRight: '5%',
}));

const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  padding: '15px 0',
}));

export const TrainerProfilePageSupport: React.FC = () => {
  const navigate = useNavigate();

  const handleExternalLink = (url: string) => {
    window.open(url, '_blank');
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <Appbar showMenu={true} showCloseIcon={true} />
      <BackButton onClick={handleBackClick}>
        <ArrowBackIcon sx={{ fontSize: '30px', fontWeight: 'bold', color: 'black' }} />
      </BackButton>
      <FirstTitle>All Help</FirstTitle>
      <ListItemButtonStyled onClick={() => handleExternalLink('https://gyfrapp.com/pages/faq')}>
        <IconBox>
          <QuestionMarkIcon style={iconStyles} />
        </IconBox>
        <CustomListItemText primary='Frequently Asked Questions' />
        <ArrowIconBox>
          <ArrowForwardIosIcon />
        </ArrowIconBox>
      </ListItemButtonStyled>
      <Divider />
      <ListItemButtonStyled onClick={() => handleExternalLink('https://gyfrapp.com/pages/contactus')}>
        <IconBox>
          <SupportIcon style={iconStyles} />
        </IconBox>
        <CustomListItemText primary='Contact Gyfr Support' />
        <ArrowIconBox>
          <ArrowForwardIosIcon />
        </ArrowIconBox>
      </ListItemButtonStyled>
      <Title>Safety Issues</Title>
      <ListItemButtonStyled onClick={() => handleExternalLink('https://gyfrapp.com/pages/reportincident')}>
        <IconBox>
          <img src='../../../../accident.svg' style={iconStyles} alt='Accident Icon' />
        </IconBox>
        <CustomListItemText primary='Report an accident or safety concern' />
        <ArrowIconBox>
          <ArrowForwardIosIcon />
        </ArrowIconBox>
      </ListItemButtonStyled>
      <Title>App Suggestions</Title>
      <ListItemButtonStyled onClick={() => handleExternalLink('https://gyfrapp.com/pages/contactus')}>
        <IconBox>
          <ThumbsUpDown style={iconStyles} />
        </IconBox>
        <CustomListItemText primary='Share your feedback' />
        <ArrowIconBox>
          <ArrowForwardIosIcon />
        </ArrowIconBox>
      </ListItemButtonStyled>
    </>
  );
};
