import React, { useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import {
  NoPage,
  ClientSignInPage,
  ClientSignUpPage,
  ClientValidationPage,
  TrainerSignInPage,
  TrainerSignUpPage,
  TrainerInfoPage,
  TrainerIdentityPage,
  HomePage,
  HomePageSession,
  TrainerExperiencePage,
  TrainerDonePage,
  TrainerHomePage,
  TrainerAcceptPage,
  DrawbarOptions,
  ProfilePage,
  ProfilePageSupport,
  VideoPage,
  FindTrainer,
  TrainerRatingPage,
  TrainerProfilePage,
  ClientProfileSessions,
  TrainerProfileSessions,
  ProfilePageInfo,
  TrainerProfilePageInfo,
  ClientRatingPage,
  TrainerProfilePayout,
  TrainerTipPayout,
  TrainerProfilePageSupport,
  ForgetPasswordPage,
} from './frontend';
import { Backdrop, Box, Modal, Typography } from '@mui/material';
import { styled } from '@mui/system';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import store, { persistor } from './backend/redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { ClientWaitPage } from './frontend/pages/client-wait';
import { TrainerWaitPage } from './frontend/pages/trainer-wait';

function App() {
  const [isLandscape, setIsLandscape] = useState(false);
  const [isLaptopDevice, setIsLaptopDevice] = useState(false);

  const RedirectToExternal: React.FC = () => {
    useEffect(() => {
      window.location.replace('https://gyfrapp.com');
    }, []);

    return null;
  };

  const isLaptop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile = /iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent);
    return !isMobile && window.innerWidth >= 1024; // I am assuming laptops have a minimum width of 1024px
  };

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    const handleResize = () => {
      setIsLaptopDevice(isLaptop());
      handleOrientationChange();
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const showWarning = isLandscape && !isLaptopDevice;

  const WhiteABox = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    color: 'black',
    fontWeight: 'bold',
    border: '2px solid grey',
    borderColor: theme.palette.divider,
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    marginTop: '50px',
  }));

  const CustomBackdrop = styled(Backdrop)({
    zIndex: -1,
    backdropFilter: 'blur(10px)', //  blur effect
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  });
  return (
    <>
      {showWarning ? (
        <Modal
          open={showWarning}
          onClose={() => {}}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 999, opacity: 1.9 }}
          slots={{
            backdrop: CustomBackdrop,
          }}
        >
          <WhiteABox sx={{ width: '350', height: '250px' }}>
            <ErrorOutlineIcon
              sx={{ backgroundColor: 'white', color: 'red', mb: 1, justifyContent: 'center', fontSize: 90 }}
            ></ErrorOutlineIcon>
            <Typography id='modal-modal-description' sx={{ mt: 2, textAlign: 'center' }}>
              Please rotate your device to landscape mode for <br></br>better performance.
            </Typography>
          </WhiteABox>
        </Modal>
      ) : (
        <>
          <div></div>
        </>
      )}

      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routes>
            {/* general / both */}
            <Route path='/' element={<RedirectToExternal />} />
            <Route path='/drawbar' element={<DrawbarOptions />} />
            <Route path='/video-session' element={<VideoPage />} />
            <Route path='/forget-password' element={<ForgetPasswordPage />} />

            {/* client side */}
            <Route path='/client-login' element={<ClientSignInPage />} />
            <Route path='/client-signup' element={<ClientSignUpPage />} />
            <Route path='/client-validation' element={<ClientValidationPage />} />
            <Route path='/home-page' element={<HomePage />} />
            <Route path='/home-session' element={<HomePageSession />} />
            <Route path='/client-profile' element={<ProfilePage />} />
            <Route path='/profile-support' element={<ProfilePageSupport />} />
            <Route path='/find-trainer' element={<FindTrainer />} />
            <Route path='/client-sessions' element={<ClientProfileSessions />} />
            <Route path='/client-wait' element={<ClientWaitPage />} />
            <Route path='/client-info' element={<ProfilePageInfo />} />
            <Route path='/client-rating' element={<ClientRatingPage />} />
            <Route path='/trainer-tip' element={<TrainerTipPayout />} />

            {/* trainer side */}
            <Route path='/trainer-login' element={<TrainerSignInPage />} />
            <Route path='/trainer-signup' element={<TrainerSignUpPage />} />
            <Route path='/trainer-information' element={<TrainerInfoPage />} />
            <Route path='/trainer-identity' element={<TrainerIdentityPage />} />
            <Route path='/trainer-experience' element={<TrainerExperiencePage />} />
            <Route path='/trainer-done' element={<TrainerDonePage />} />
            <Route path='/trainer-accept' element={<TrainerAcceptPage />} />
            <Route path='/trainer-home' element={<TrainerHomePage />} />
            <Route path='/trainer-rating' element={<TrainerRatingPage />} />
            <Route path='/trainer-profile' element={<TrainerProfilePage />} />
            <Route path='/trainer-wait' element={<TrainerWaitPage />} />
            <Route path='/trainer-sessions' element={<TrainerProfileSessions />} />
            <Route path='/trainer-payout' element={<TrainerProfilePayout />} />
            <Route path='/trainer-support' element={<TrainerProfilePageSupport />} />
            <Route path='/trainer-info' element={<TrainerProfilePageInfo />} />

            {/* Routes to handle error */}
            <Route path='/no-page' element={<NoPage />} />
            <Route path='*' element={<NoPage />} />
          </Routes>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
