import React, { useEffect, useState } from 'react';
import { Box, Typography, Avatar, Button, ListItemButton, ListItemText, styled, Divider } from '@mui/material';
import {
  SupportAgent as SupportIcon,
  Psychology as PsychologyIcon,
  Info as InfoIcon,
  CreditCard as CreditCardIcon,
  PermIdentity as YourInfoIcon,
  History as HistoryIcon,
  Diversity3 as FriendsIcon,
  Star as StarIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Appbar } from '../../components';
import { firebaseAuth } from '../../../backend/firebase';
import { useDispatch } from 'react-redux';
import { setUid } from '../../../backend/redux/store';
import firebaseApi from '../../../backend/firebase/FirebaseApi';
import { base } from '../../../backend/utils/BaseUrl';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../../../backend/firebase/FirebaseConfig';

export const TrainerProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [generalError, setGeneralError] = useState<string>('');
  const [firstName, setFirstName] = useState('Sam');
  const [lastName, setLastName] = useState('Smith');
  const [rating, setRating] = useState(0);
  const [profileImage, setProfileImage] = useState<string>('');

  const handleExternalLink = (url: string) => {
    window.open(url, '_blank');
  };

  const handleSignOutButton = async () => {
    try {
      await signOut(firebaseAuth);
      dispatch(setUid(null));

      // Optionally close the window if sign-out is part of a popup
      if (window.opener) {
        window.close();
      }
    } catch (error) {
      setGeneralError('An unexpected error occurred. Please try again later.');
      console.log(error);
    }
    navigate('/');
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        fetchTrainerInfo(user);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchTrainerInfo = async (user: any) => {
    try {
      const uid = user.uid;
      const data: any = await firebaseApi.readSingleTrainer(base, uid);
      console.log(data);
      const profileImageRef = ref(storage, `trainers/${uid}/identificationImage/face`);
      const profileImageURL = await getDownloadURL(profileImageRef);
      setProfileImage(profileImageURL || firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase());

      setFirstName(data.firstName || '');
      setLastName(data.lastName || '');
      setRating(data.rating || 0.0);
    } catch (error) {
      console.error('Error fetching trainer info:', error);
    }
  };

  return (
    <>
      <Appbar showMenu={true} showCloseIcon={true} />
      <ProfileContainer>
        <ProfileHeader>
          <ProfileInfo>
            <Typography variant='h4' component='h1' sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
              {firstName}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
              <StarIcon sx={{ color: 'black', marginRight: '5px' }} />
              <Typography variant='h6' component='h2'>
                {rating.toFixed(2)}
              </Typography>
            </Box>
          </ProfileInfo>
          <Avatar alt='Profile Picture' src={profileImage} sx={{ width: '112px', height: '100px' }} />
        </ProfileHeader>
        {/* TODO: Add navigation route within App */}
        <ButtonRow>
          <CustomButton onClick={() => navigate('/trainer-info')}>
            <YourInfoIcon />
            <Typography sx={{ fontWeight: 'bold' }}>Your Information</Typography>
          </CustomButton>

          <CustomButton onClick={() => navigate('/trainer-payout')}>
            <CreditCardIcon />
            <Typography sx={{ fontWeight: 'bold' }}>Your Earnings</Typography>
          </CustomButton>
        </ButtonRow>
        <InviteButton
          onClick={() => handleExternalLink('https://gyfrapp.com/pages/affiliate-marketing')}
          sx={{ marginTop: '20px' }}
        >
          <InviteTextContainer>
            <InviteTitle variant='subtitle1'>Become an affiliate and earn!</InviteTitle>
            <InviteSubtitle variant='caption'>
              Earn 10% commission on every session booked through your referral!
            </InviteSubtitle>
          </InviteTextContainer>
          <FriendsIcon sx={{ marginLeft: '20px', fontSize: '60px' }} />
        </InviteButton>
      </ProfileContainer>
      {/* TODO: Add navigation hyperlink */}
      <ListItemButtonStyled onClick={() => navigate('/trainer-support')}>
        <IconBox sx={iconStyles}>
          <SupportIcon />
        </IconBox>
        <CustomListItemText primary='Gyfr Support' />
        <ArrowIconBox>
          <ArrowForwardIosIcon />
        </ArrowIconBox>
      </ListItemButtonStyled>
      <Divider />
      <ListItemButtonStyled onClick={() => handleExternalLink('https://gyfrapp.com/pages/personal-trainer-guide')}>
        <IconBox sx={iconStyles}>
          <PsychologyIcon />
        </IconBox>
        <CustomListItemText primary='Learning Center' />
        <ArrowIconBox>
          <ArrowForwardIosIcon />
        </ArrowIconBox>
      </ListItemButtonStyled>
      <Divider />
      <ListItemButtonStyled onClick={() => navigate('/trainer-sessions')}>
        <IconBox sx={iconStyles}>
          <HistoryIcon />
        </IconBox>
        <CustomListItemText primary='Previous Sessions' />
        <ArrowIconBox>
          <ArrowForwardIosIcon />
        </ArrowIconBox>
      </ListItemButtonStyled>
      <Divider />
      <ListItemButtonStyled onClick={() => handleExternalLink('https://gyfrapp.com/')}>
        <IconBox sx={iconStyles}>
          <InfoIcon />
        </IconBox>
        <CustomListItemText primary='Visit Our Website' />
        <ArrowIconBox>
          <ArrowForwardIosIcon />
        </ArrowIconBox>
      </ListItemButtonStyled>
      <ProfileContainer>
        <CustomButton variant='contained' onClick={handleSignOutButton} sx={{ width: '90%', textTransform: 'none' }}>
          Sign Out
        </CustomButton>
      </ProfileContainer>
    </>
  );
};

const ProfileContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '20px',
  padding: '20px',
  width: '100%',
  maxWidth: '90%',
  margin: '0 auto',
}));

const ProfileHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '85%',
  justifyContent: 'space-between',
  marginTop: '15px',
}));

const ProfileInfo = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

const ButtonRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  marginTop: '20px',
  gap: '20px',
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
    width: '100%',
    gap: '10px',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    width: '100%',
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  flex: '1',
  backgroundColor: '#D9D9D9',
  color: 'black',
  borderRadius: '15px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#D0D0D0',
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
}));

const InviteButton = styled(Button)(({ theme }) => ({
  width: '100%',
  backgroundColor: '#D9D9D9',
  color: 'black',
  borderRadius: '15px',
  textTransform: 'none',
  padding: '10px',
  textAlign: 'left',
  marginTop: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '&:hover': {
    backgroundColor: '#D0D0D0',
  },
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: '8px',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const InviteTextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('xs')]: {
    width: '100%',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const InviteTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  [theme.breakpoints.down('xs')]: {
    fontSize: '16px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '18px',
  },
}));

const InviteSubtitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    fontSize: '12px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '14px',
  },
}));

const CustomListItemText = styled(ListItemText)(({ theme }) => ({
  marginLeft: '10px',
  textAlign: 'left',
  fontWeight: 'bold',
  fontFamily: 'Roboto',
}));

const iconStyles = {
  display: 'flex',
  alignItems: 'center',
  color: 'black',
};

const IconBox = styled(Box)(({ theme }) => ({
  marginLeft: '7%',
}));

const ArrowIconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: 'black',
  marginRight: '5%',
}));

const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  padding: '15px 0',
}));
