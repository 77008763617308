import { createTheme } from '@mui/material/styles';
import { styled } from '@mui/system';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#F27C22',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#DEDEDE',
    },
    background: {
      paper: '#fff',
      default: '#f4f4f4',
    },
    text: {
      primary: '#000', // default text color
      secondary: 'gray',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export const CustomCardContainer = styled(Card)(({ theme }) => ({
  width: '50%',
  borderRadius: '15px',
  padding: '20px',
  margin: '20px auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  border: 'none',
  boxShadow: 'none', // Remove the box shadow of the Card Component
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    padding: '10px',
    margin: '10px auto',
    borderRadius: '10px',
  },
  [theme.breakpoints.between('sm', 'md')]: {
    width: '80%',
    padding: '15px',
    margin: '15px auto',
    borderRadius: '20px',
    maxHeight: '90vh',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    width: '70%',
    padding: '20px',
    margin: '20px auto',
    borderRadius: '25px',
    maxHeight: '85vh',
  },
  [theme.breakpoints.between('lg', 'xl')]: {
    width: '60%',
    padding: '25px',
    margin: '25px auto',
    borderRadius: '30px',
    maxHeight: '200vh',
  },
  [theme.breakpoints.up('xl')]: {
    width: '50%',
    padding: '30px',
    margin: '30px auto',
    borderRadius: '35px',
    maxHeight: '75vh',
  },
}));

export const CustomContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(8),
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(11),
  },
}));