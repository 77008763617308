import React from 'react';
import { List, ListItemText, IconButton, ListItemButton, styled, Divider, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Appbar } from '../appbar';

const CustomListItemText = styled(ListItemText)(({ theme }) => ({
  marginLeft: '10px',
  textAlign: 'left',
  fontWeight: 'bold',
  fontFamily: 'Roboto',
}));

const iconStyles = {
  display: 'flex',
  alignItems: 'center',
  color: 'black',
};

const IconBox = styled(Box)(({ theme }) => ({
  marginLeft: '4%',
}));

const ArrowIconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: 'black',
  marginRight: '4%',
}));

const CustomList = styled(List)(({ theme }) => ({
  marginTop: '5%',
}));

const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  padding: '15px 0',
}));

export const DrawbarOptions: React.FC = () => {
  const handleExternalLink = (url: string) => {
    window.open(url, '_blank');
  };
  return (
    <>
      <Appbar showMenu={true} showCloseIcon={true} />
      <CustomList>
        <ListItemButtonStyled onClick={() => handleExternalLink('https://gyfrapp.com/')}>
          <IconBox sx={iconStyles}>
            <HomeIcon sx={{ fontSize: '30px', marginLeft: -0.4, marginRight: -0.4, marginTop: -0.3 }} />
          </IconBox>
          <CustomListItemText primary='Home' />
          <ArrowIconBox>
            <ArrowForwardIosIcon />
          </ArrowIconBox>
        </ListItemButtonStyled>
        <Divider />
        <ListItemButtonStyled onClick={() => handleExternalLink('https://gyfrapp.com/pages/about-us')}>
          <IconBox sx={iconStyles}>
            <InfoIcon />
          </IconBox>
          <CustomListItemText primary='About Us' />
          <ArrowIconBox>
            <ArrowForwardIosIcon />
          </ArrowIconBox>
        </ListItemButtonStyled>
        <Divider />
        <ListItemButtonStyled onClick={() => handleExternalLink('https://gyfrapp.com/pages/faq')}>
          <IconBox sx={iconStyles}>
            <QuestionAnswerIcon />
          </IconBox>
          <CustomListItemText primary='Frequently Asked Questions' />
          <ArrowIconBox>
            <ArrowForwardIosIcon />
          </ArrowIconBox>
        </ListItemButtonStyled>
      </CustomList>
    </>
  );
};
